/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RestDevFeatureRequest } from '../model';
// @ts-ignore
import { TenantFileDto } from '../model';
/**
 * DevFeatureControllerApi - axios parameter creator
 * @export
 */
export const DevFeatureControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTenants: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('exportTenants', 'requestBody', requestBody)
            const localVarPath = `/api/v1/dev-features/tenants/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fiCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessLog: async (fiCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fiCode' is not null or undefined
            assertParamExists('getProcessLog', 'fiCode', fiCode)
            const localVarPath = `/api/v1/dev-features/get-process-log/{fiCode}`
                .replace(`{${"fiCode"}}`, encodeURIComponent(String(fiCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<RestDevFeatureRequest>} restDevFeatureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runQpScripts: async (restDevFeatureRequest: Array<RestDevFeatureRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restDevFeatureRequest' is not null or undefined
            assertParamExists('runQpScripts', 'restDevFeatureRequest', restDevFeatureRequest)
            const localVarPath = `/api/v1/dev-features/run-qp-scripts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restDevFeatureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: string; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRowkeys: async (requestBody: { [key: string]: string; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateRowkeys', 'requestBody', requestBody)
            const localVarPath = `/api/v1/dev-features/rowkeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint import tenants from other environments. 
         * @summary Import Tenants
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadZip: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadZip', 'file', file)
            const localVarPath = `/api/v1/dev-features/tenants/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevFeatureControllerApi - functional programming interface
 * @export
 */
export const DevFeatureControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevFeatureControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportTenants(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportTenants(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fiCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProcessLog(fiCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProcessLog(fiCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<RestDevFeatureRequest>} restDevFeatureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runQpScripts(restDevFeatureRequest: Array<RestDevFeatureRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runQpScripts(restDevFeatureRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: string; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRowkeys(requestBody: { [key: string]: string; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRowkeys(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint import tenants from other environments. 
         * @summary Import Tenants
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadZip(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadZip(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DevFeatureControllerApi - factory interface
 * @export
 */
export const DevFeatureControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevFeatureControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTenants(requestBody: Array<string>, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.exportTenants(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fiCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessLog(fiCode: string, options?: any): AxiosPromise<object> {
            return localVarFp.getProcessLog(fiCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<RestDevFeatureRequest>} restDevFeatureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runQpScripts(restDevFeatureRequest: Array<RestDevFeatureRequest>, options?: any): AxiosPromise<object> {
            return localVarFp.runQpScripts(restDevFeatureRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: string; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRowkeys(requestBody: { [key: string]: string; }, options?: any): AxiosPromise<object> {
            return localVarFp.updateRowkeys(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint import tenants from other environments. 
         * @summary Import Tenants
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadZip(file: any, options?: any): AxiosPromise<TenantFileDto> {
            return localVarFp.uploadZip(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DevFeatureControllerApi - interface
 * @export
 * @interface DevFeatureControllerApi
 */
export interface DevFeatureControllerApiInterface {
    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevFeatureControllerApiInterface
     */
    exportTenants(requestBody: Array<string>, options?: AxiosRequestConfig): AxiosPromise<Array<string>>;

    /**
     * 
     * @param {string} fiCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevFeatureControllerApiInterface
     */
    getProcessLog(fiCode: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {Array<RestDevFeatureRequest>} restDevFeatureRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevFeatureControllerApiInterface
     */
    runQpScripts(restDevFeatureRequest: Array<RestDevFeatureRequest>, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {{ [key: string]: string; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevFeatureControllerApiInterface
     */
    updateRowkeys(requestBody: { [key: string]: string; }, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * This endpoint import tenants from other environments. 
     * @summary Import Tenants
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevFeatureControllerApiInterface
     */
    uploadZip(file: any, options?: AxiosRequestConfig): AxiosPromise<TenantFileDto>;

}

/**
 * DevFeatureControllerApi - object-oriented interface
 * @export
 * @class DevFeatureControllerApi
 * @extends {BaseAPI}
 */
export class DevFeatureControllerApi extends BaseAPI implements DevFeatureControllerApiInterface {
    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevFeatureControllerApi
     */
    public exportTenants(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return DevFeatureControllerApiFp(this.configuration).exportTenants(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fiCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevFeatureControllerApi
     */
    public getProcessLog(fiCode: string, options?: AxiosRequestConfig) {
        return DevFeatureControllerApiFp(this.configuration).getProcessLog(fiCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<RestDevFeatureRequest>} restDevFeatureRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevFeatureControllerApi
     */
    public runQpScripts(restDevFeatureRequest: Array<RestDevFeatureRequest>, options?: AxiosRequestConfig) {
        return DevFeatureControllerApiFp(this.configuration).runQpScripts(restDevFeatureRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: string; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevFeatureControllerApi
     */
    public updateRowkeys(requestBody: { [key: string]: string; }, options?: AxiosRequestConfig) {
        return DevFeatureControllerApiFp(this.configuration).updateRowkeys(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint import tenants from other environments. 
     * @summary Import Tenants
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevFeatureControllerApi
     */
    public uploadZip(file: any, options?: AxiosRequestConfig) {
        return DevFeatureControllerApiFp(this.configuration).uploadZip(file, options).then((request) => request(this.axios, this.basePath));
    }
}
