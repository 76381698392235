import { BadgeProps } from "antd";

import { DeploymentsState } from "@ni/sdk/models";

const getStatusColor = (state: DeploymentsState): { status: BadgeProps["status"]; text: string } => {
  switch (state) {
    case DeploymentsState.SUCCESS:
      return { status: "success", text: "Success" };
    case DeploymentsState.FAILURE:
      return { status: "error", text: "Failure" };
    case DeploymentsState.IN_PROGRESS:
      return { status: "processing", text: "In Progress" };
    default:
      return { status: "default", text: "Unknown" };
  }
};

export { getStatusColor };
