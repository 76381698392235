import { useCallback, useEffect } from "react";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";

import { useReduxState } from "@ni/common/hooks";
import { DeploymentsApi } from "@ni/sdk/apis";
import { Deployment, DeploymentsState } from "@ni/sdk/models";

const deploymentApi = new DeploymentsApi();
const POLL_DURATION: number = 30000;

interface Props {
  deploymentId: number;
}

const useDeploymentDetails = ({ deploymentId }: Props) => {
  const [deployment, setDeployment] = useReduxState<Deployment>("deployment", {});
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");

  const navigate = useNavigate();

  const fetchDepeloymentById = useCallback(
    (id: number, showLoader?: boolean) => {
      if (showLoader) setIsLoading(true);

      deploymentApi
        .getDeploymentById(id)
        .then(res => {
          if (res) {
            setDeployment(res.data);
          }
        })
        .catch(() => {})
        .finally(() => {
          if (showLoader) setIsLoading(false);
        });
    },
    [setDeployment, setIsLoading],
  );

  const retryDeployment = useCallback(
    (id: number) => {
      setIsLoading(true);

      deploymentApi
        .retryDeployment(id)
        .then(() => {
          navigate("/admin/deployments");

          notification.success({
            placement: "topRight",
            duration: 5,
            message: "Success",
            description: "New deployment instance has been initiated.",
          });
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    },
    [navigate, setIsLoading],
  );

  useEffect(() => {
    const id = Number(deploymentId);

    if (!deployment || !Object.keys(deployment).length || deployment.id !== id) fetchDepeloymentById(id, true);

    const pollDeployments = setInterval(() => {
      if (deployment.state === DeploymentsState.IN_PROGRESS) {
        fetchDepeloymentById(id, false);
      }
    }, POLL_DURATION);

    return () => clearInterval(pollDeployments);
  }, [deployment, deploymentId, fetchDepeloymentById]);

  return { fetchDepeloymentById, retryDeployment, deployment, isLoading };
};

export { useDeploymentDetails };
