import { useMemo } from "react";
import { Badge, Divider, Table, TableColumnType, Tree, Typography } from "antd";
import { ExpandableConfig } from "antd/lib/table/interface";
import { BasicDataNode, DataNode } from "antd/lib/tree";
import { getStatusColor } from "libs/admin/src/lib/utils";
import { Link } from "react-router-dom";

import { DownOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { Deployment, DeploymentsState } from "@ni/sdk/models";

interface IntegrationData {
  key: string;
  value: string;
  treeData?: (BasicDataNode | DataNode)[];
}

interface Props {
  deployment: Deployment;
}

const IntegrationsTable = ({ deployment }: Props) => {
  const [isLoading] = useReduxState<boolean>("isLoading");

  const { integrationsData, integrationsColumns, shouldRender } = useMemo(() => {
    const shouldRender: boolean = deployment.state === DeploymentsState.SUCCESS;

    const recursivelyBuildChildren = (data: Record<string, string[]>) => {
      return Object.entries(data).map(([key, value]) => ({
        key,
        title: key,
        children: value.map(x => ({ title: x, key: x })),
      }));
    };

    const integrationsData: IntegrationData[] = Object.entries(deployment.integrationPr ?? {}).map(([key, value]) => {
      if (key.includes("error")) {
        return {
          key,
          treeData: Object.entries(value ?? {})
            .filter(([k]) => k !== "failed")
            .map(([k, v]) => ({
              key: k,
              title: k,
              children: recursivelyBuildChildren(v as Record<string, string[]>),
            })),
        };
      }

      return { key, value };
    });

    const integrationsColumns: TableColumnType<IntegrationData>[] = [
      {
        key: "system",
        title: "System Name",
        dataIndex: ["key"],
        width: "50%",
      },
    ];

    if (integrationsData.some(x => x.key.includes("error"))) {
      integrationsColumns.push({
        key: "status",
        title: "Status",
        width: "3.5%",
        rowScope: "row",
        render: () => <Badge {...getStatusColor(DeploymentsState.FAILURE)} />,
      });
    } else {
      integrationsColumns.push({
        key: "link",
        title: "Pull Request Link",
        width: "50%",
        render: (_, item) =>
          item.value && (
            <Link to={item.value} target="_blank" rel="noopener noreferrer">
              <Typography.Text copyable={true}>{item?.value}</Typography.Text>
            </Link>
          ),
      });
    }

    return { integrationsData, integrationsColumns, shouldRender };
  }, [deployment.integrationPr, deployment.state]);

  const expandable: ExpandableConfig<IntegrationData> = {
    expandedRowKeys: ["dudamel-error"],
    expandIconColumnIndex: -1,
    expandedRowRender: record => (
      <Tree
        treeData={record.treeData}
        switcherIcon={<DownOutlined />}
        showLine={true}
        selectable={false}
        defaultExpandAll={true}
      />
    ),
  };

  return (
    shouldRender && (
      <div>
        <Divider orientation="left" orientationMargin={0}>
          Integrations Stages
        </Divider>

        <Table<IntegrationData>
          size="middle"
          rowKey="key"
          columns={integrationsColumns}
          dataSource={integrationsData}
          expandable={expandable}
          loading={isLoading}
          pagination={false}
          bordered={true}
        />
      </div>
    )
  );
};

export { IntegrationsTable };
