import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Collapse, CollapsePanelProps, Space, Typography } from "antd";
import { DefaultOptionType } from "antd/lib/select/";

import { useReduxState } from "@ni/common/hooks";
import { NetworkForm } from "@ni/common/ui";
import { DevFeatureControllerApi, TenantApi } from "@ni/sdk/apis";
import { DashboardTenant } from "@ni/sdk/models";

interface Props extends Omit<CollapsePanelProps, "header"> {}

const ExportTenant = ({ ...props }: Props) => {
  const [tenantOptions, setTenantOptions] = useState<DefaultOptionType[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<string[]>([]);
  const [isLoading] = useReduxState<boolean>("isLoading");

  const { tenantApi, devFeaturesApi } = useMemo(
    () => ({ tenantApi: new TenantApi(), devFeaturesApi: new DevFeatureControllerApi() }),
    [],
  );

  useEffect(() => {
    tenantApi
      .getDashboardTenants({
        pageLimits: {
          size: 200,
        },
      })
      .then(({ data }) => {
        if (data.content && data.content.length) {
          setTenantOptions(
            (data.content as unknown as DashboardTenant[])
              .filter(x => !!x.externalCode)
              .map(x => ({ label: x.name, value: x.externalCode })),
          );
        }
      })
      .catch(() => {});
  }, [tenantApi]);

  const handleExportTenant = useCallback(() => {
    devFeaturesApi
      .exportTenants(selectedTenant, { responseType: "blob" })
      .then(response => {
        const blob = response.data;
        const url = window.URL.createObjectURL(blob as unknown as Blob);
        const a = document.createElement("a");

        const contentDisposition = response.headers["content-disposition"] as string;
        const filename = contentDisposition
          ? contentDisposition.split("filename=")[1]?.replace(/["']/g, "")
          : "downloaded-file";

        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {});
  }, [devFeaturesApi, selectedTenant]);

  return (
    <Collapse.Panel {...props} header="Export Tenant">
      <Space direction="vertical" size={16} className="w-p-100">
        <div>
          <Typography.Text strong={true}>Export</Typography.Text>
          <Typography.Paragraph type="secondary" style={{ maxWidth: 880, width: 880 }}>
            This feature is used to export tenant data.
          </Typography.Paragraph>
        </div>

        <NetworkForm.Select
          placeholder="Select tenants"
          mode="multiple"
          options={tenantOptions}
          onChange={setSelectedTenant}
          value={selectedTenant}
          style={{ width: 500 }}
          optionFilterProp="label"
        />

        <Button
          type="primary"
          onClick={handleExportTenant}
          loading={isLoading}
          disabled={!selectedTenant.length}
          style={{ width: 150, marginTop: 16 }}
        >
          Export
        </Button>
      </Space>
    </Collapse.Panel>
  );
};

export { ExportTenant };
