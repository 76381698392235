import { useEffect, useMemo, useState } from "react";

import { DeploymentsApi } from "@ni/sdk/apis";
import { Deployment, DeploymentsState, QpRowkey } from "@ni/sdk/models";

interface Props {
  currentRowkey?: string;
  qpRowkeys: QpRowkey[];
}

const deploymentsApi = new DeploymentsApi();

const useDeploymentState = ({ currentRowkey, qpRowkeys }: Props) => {
  const [pendingDeps, setPendingDeps] = useState<Deployment[]>([]);

  const tenant = useMemo(() => qpRowkeys.find(x => x.rowkey === currentRowkey), [currentRowkey, qpRowkeys]);

  useEffect(() => {
    setPendingDeps([]);

    if (!currentRowkey && !tenant) return;

    deploymentsApi
      .getDashboardDeployments({ search: tenant?.name })
      .then(({ data }) => {
        const deployments = data.content?.filter(x => x.tenantExternalCode === currentRowkey);
        const sortedDeployments = deployments?.sort(
          (a, b) => new Date(a.startTime!).getTime() - new Date(b.startTime!).getTime(),
        );
        const lastTwoDeps = sortedDeployments?.filter(x => x.state !== DeploymentsState.SUCCESS)?.slice(-2);
        if (lastTwoDeps && lastTwoDeps.length > 0) setPendingDeps(lastTwoDeps);
      })
      .catch(() => {});
  }, [currentRowkey, tenant]);

  return pendingDeps;
};

export { useDeploymentState };
