// 2.1_PROD_TYPE_SELECT
export const BALANCE_OWNER = "balance-owner";

// 3.1.1_PROD_BASE
export const NIC_CCY = "nic-ccy";
export const CO_BADGED_NAME = "co-badged-name";
export const NIC_MADA_USE = "nic-mada-use";

// 3.1.1.1_MULTI_CUR_DET
export const PRODUCT_CURRENCY = "product-currency";
export const TENANT_CURRENCY = "tenant-currency";
export const TENANT_COUNTRY = "tenant-country";
export const NUMBER_OF_WALLETS_MULTICUR = "number-of-wallets-multicur";

// 3.2_CARD_PROD
export const REISS_PHYS = "reiss-phys";
export const VIRT_PHYS = "virt-phys";
export const SDE = "sde";
export const PERSO = "perso";

// 3.3.1_CR_LIM

export const NIC_CRLIM_MIN = "nic-crlim-min";
export const NIC_CRLIM_MAX = "nic-crlim-max";
export const NIC_CASH_LIMIT_ACC_RATE = "nic-cash-limit-acc-rate";
export const NIC_OVL_AUTH_VAR = "nic-ovl-auth-var";

// 3.3.2_MTP_PERC

export const NIC_SMS_DUE_FLAG = "sms-due-reminder-flag";
export const NIC_SMS_DUE_DAYS = "nic-sms-due-reminder-days";
export const NIC_DUE_DATE_DAYS = "nic-due-date-days";
export const NIC_DLQ_DATE_DAYS = "nic-dlq-date-days";
export const NIC_MTP_VAL_RATE = "nic-mtp-val-rate";
export const NIC_MTP_VAL_BASE = "nic-mtp-val-base";
export const NIC_FP_DATE_BASE = "nic-fp-date-base";

// 3.3.4_INT_RET_CASH

export const NIC_INT_RATE_RETAIL = "nic-int-rate-retail";
export const NIC_INT_RATE_CASH = "nic-int-rate-cash";

// 3.3_CARD_PROD
export const NIC_CARD_SUBTYPE_PINSET_ALLOWED = "nic-card-subtype-pinset-allowed";
export const NIC_CARD_SUBTYPE_EXP_MONTHS = "nic-card-subtype-exp-months";
export const NIC_CARD_SUBTYPE_AUTORENEW_DAYS = "nic-card-subtype-autorenew-days";

export const NIC_CARD_SUBTYPE_PINSET_ALLOWED_FOR_LOCKED = "nic-card-subtype-pinset-allowed-for-locked";
export const NIC_CARD_SUBTYPE_ABU_ACTIVE = "nic-card-subtype-abu-active";
export const NIC_CARD_SUBTYPE_TOKEN_USED = "nic-card-subtype-token-used";
export const NIC_CARD_SUBTYPE_TOKEN_MADA_USED = "nic-card-subtype-token-mada-used";

export const NIC_INT_BASE = "nic-int-base";
export const NIC_DD_WR_ASK100 = "nic-dd-wr-ask100";
export const NIC_DLQ_LEVEL_TO_FREEZE = "nic-dlq-level-to-freeze";
export const NIC_PAST_DUE_DAYS_CALC_MODE = "nic-past-due-days-calc-mode";

// 3.3.5_OVL_FEE
export const GRACE_OPT = "grace-opt";

// 6.1_TOPUP_LIMITS
export const PAYM_LIM_CONTR_LEVEL = "paym-lim-contr-level";
export const PAYM_LIM_PERIODS = "paym-lim-periods";

// 6.2_TOPUP_LIMITS for Accounts
// not a system value, used to control the visibility of the topup limits section
export const PAYM_D_ENABLED = "paym-d-enabled";
export const D_PAYM_A_TOTAL_ENABLED = "d-paym-a-total-enabled";
export const NIC_D_PAYM_A_TOTAL_NR = "nic-d-paym-a-total-nr";
export const NIC_D_PAYM_A_TOTAL_AM = "nic-d-paym-a-total-am";
export const NIC_D_PAYM_A_TOTAL_SINGLE = "nic-d-paym-a-total-single";

export const PAYM_B_ENABLED = "paym-b-enabled";
export const B_PAYM_A_TOTAL_ENABLED = "b-paym-a-total-enabled";
export const NIC_B_PAYM_A_TOTAL_NR = "nic-b-paym-a-total-nr";
export const NIC_B_PAYM_A_TOTAL_AM = "nic-b-paym-a-total-am";
export const NIC_B_PAYM_A_TOTAL_SINGLE = "nic-b-paym-a-total-single";

export const PAYM_D365_ENABLED = "paym-d365-enabled";
export const D365_PAYM_A_TOTAL_ENABLED = "d365-paym-a-total-enabled";
export const NIC_D365_PAYM_A_TOTAL_NR = "nic-d365-paym-a-total-nr";
export const NIC_D365_PAYM_A_TOTAL_AM = "nic-d365-paym-a-total-am";
export const NIC_D365_PAYM_A_TOTAL_SINGLE = "nic-d365-paym-a-total-single";

export const PAYM_E_ENABLED = "paym-e-enabled";
export const E_PAYM_A_TOTAL_ENABLED = "e-paym-a-total-enabled";
export const NIC_E_PAYM_A_TOTAL_NR = "nic-e-paym-a-total-nr";
export const NIC_E_PAYM_A_TOTAL_AM = "nic-e-paym-a-total-am";
export const NIC_E_PAYM_A_TOTAL_SINGLE = "nic-e-paym-a-total-single";

// 6.3_TOPUP_LIMITS for Cards
// Per Day
export const D_PAYM_C_TOTAL_ENABLED = "d-paym-c-total-enabled";
export const NIC_D_PAYM_C_TOTAL_NR = "nic-d-paym-c-total-nr";
export const NIC_D_PAYM_C_TOTAL_AM = "nic-d-paym-c-total-am";
export const NIC_D_PAYM_C_TOTAL_SINGLE = "nic-d-paym-c-total-single";

// Per month
export const M_PAYM_C_TOTAL_ENABLED = "m-paym-c-total-enabled";
export const NIC_M_PAYM_C_TOTAL_NR = "nic-m-paym-c-total-nr";
export const NIC_M_PAYM_C_TOTAL_AM = "nic-m-paym-c-total-am";
export const NIC_M_PAYM_C_TOTAL_SINGLE = "nic-m-paym-c-total-single";

// Per 365 days
export const D365_PAYM_C_TOTAL_ENABLED = "d365-paym-c-total-enabled";
export const NIC_D365_PAYM_C_TOTAL_NR = "nic-d365-paym-c-total-nr";
export const NIC_D365_PAYM_C_TOTAL_AM = "nic-d365-paym-c-total-am";
export const NIC_D365_PAYM_C_TOTAL_SINGLE = "nic-d365-paym-c-total-single";

export const E_PAYM_C_TOTAL_ENABLED = "e-paym-c-total-enabled";
export const NIC_E_PAYM_C_TOTAL_NR = "nic-e-paym-c-total-nr";
export const NIC_E_PAYM_C_TOTAL_AM = "nic-e-paym-c-total-am";
export const NIC_E_PAYM_C_TOTAL_SINGLE = "nic-e-paym-c-total-single";

export const PAYM_A_TOTAL_ENABLED = "paym-a-total-enabled";
export const TOTAL_PAYMENTS_ENABLED: { [key: string]: string[] } = {
  [PAYM_A_TOTAL_ENABLED]: [
    D_PAYM_A_TOTAL_ENABLED,
    B_PAYM_A_TOTAL_ENABLED,
    D365_PAYM_A_TOTAL_ENABLED,
    E_PAYM_A_TOTAL_ENABLED,
  ],
};

// 7.0_GEN_FEE
export const GEN_FEE_OPTIONS = "gen-fee-options";

// 7.1_GEN_FEE
export const MEM_FEE_TYPE = "mem_fee_type";
export const NIC_AMF_ACC_EX_MONTHS = "nic-amf-acc-ex-months";
export const NIC_AMF_ACC_VAL = "nic-amf-acc-val";
export const NIC_MMF_ACC_VAL = "nic-mmf-acc-val";
export const WAIVE_AMF_SPEND = "waive-amf-spend";
export const NIC_AMF_ACC_RULES = "nic-amf-acc-rules";
export const WAIVE_AMF_SPEND_AMOUNT = "waive-amf-spend-amount";

// 7.2_GEN_FEE
export const JF_ACC_ENABLED = "jf-acc-enabled";
export const NICE_JF_ACC_VAL = "nic-jf-acc-val";
export const CP_FEES_ENABLED = "cp-fees-enabled";

// 7.3_GEN_FEE
export const NIC_CP_NEW = "nic-cp-new";
export const NIC_CP_RNEW = "nic-cp-rnew";
export const NIC_CP_REPL = "nic-cp-repl";
export const NIC_CP_RENEXP = "nic-cp-renexp";
export const NIC_CP_REISS = "nic-cp-reiss";
export const NIC_CP_PLRE = "nic-cp-plre";

// 7.4_GEN_FEE
export const STMT_FEE_ENABLED = "stmt-fee-enabled";
export const NIC_STMT_FEE_VAL = "nic-stmt-fee-val";

// 7.5_GEN_FEE
export const INACT_FEE_ONCE_EN = "inact-fee-once-en";
export const NIC_INACT_FEE_ONCE_VAL = "nic-inact-fee-once-val";
export const INACT_FEE_BILLING_EN = "inact-fee-billing-en";
export const NIC_INACT_FEE_BILLING_VAL = "nic-inact-fee-billing-val";

// 8.0_TRANS_FEE

export const TRANS_FEE_OPTIONS = "trans-fee-options";

// 8.4_TRANS_FEE
export const BAL_INQ_ENABLED = "bal-inq-enabled";
export const NIC_BAL_INQ_FEE_OTHER_VAL = "nic-bal-inq-fee-other-val";
export const NIC_BAL_INQ_FEE_OTHER_MAX_NRR = "nic-bal-inq-fee-other-max-nr";

export const INQ_ALLOW_ONUS = "inq-allow-onus";
export const NIC_BAL_INQ_FEE_OTHER_ONUS_VAL = "nic-bal-inq-fee-onus-val";
export const NIC_BAL_INQ_FEE_ONUS_MAX_NR = "nic-bal-inq-fee-onus-max-nr";
export const INQ_ALLOW_DOM = "inq-allow-dom";
export const NIC_BAL_INQ_FEE_DOM_VAL = "nic-bal-inq-fee-dom-val";
export const NIC_BAL_INQ_FEE_DOM_MAX_NR = "nic-bal-inq-fee-dom-max-nr";

// 8.5_TRANS_FEE
export const DECLINE_FEE_ENABLED = "decline-fee-enabled";
export const NIC_DECL_TRANS_FEE_VAL = "nic-decl-trans-fee-val";
export const NIC_DECL_TRANS_FEE_MAX_NR = "nic-decl-trans-fee-max-nr";

// CTF
export const IPS_TRANS_FEE_ENABLED = "ips-trans-fee-enabled";

// extra
export const PLAN_CURRENCY = "plan-currency";
export const SCHEME = "scheme";
export const INTRA_REGIONAL_COUNTRIES = "intra-regional-country";
