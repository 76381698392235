import { FeatureGuard, RoutesTreeItem } from "@ni/common/types";

import { SpecificationPage } from "./SpecificationPage";
import {
  AdditionalServicesDetails,
  APIPage,
  BlockCodesList,
  CardControlPage,
  ClientIdentification,
  CreditProductsConfiguration,
  DataFrameworkPage,
  FeesTenantConfiguration,
  GeneralCardRelatedParameters,
  GeneralLoyaltySettings,
  InstallmentPaymentPlans,
  InsuranceProgramTemplatesPage,
  InterfacesPage,
  LoyaltyProgramTemplates,
  PaymentSchemasServices,
  ReportsPage,
  SmsGeneralSettings,
  SmsTemplates,
  TenantDetails,
} from ".";

export const getTenantPagesRoutes = (guard: FeatureGuard): RoutesTreeItem[] => [
  {
    name: "Tenant Details",
    route: "details",
    element: <TenantDetails />,
  },
  {
    name: "Credit Products Configuration",
    route: "credit-products-configuration",
    element: <CreditProductsConfiguration />,
    dependency:
      "$..productValues[?(@.fieldCode =='balance-owner' && @.value == 'CMS')] AND $..productValues[?(@.fieldCode == 'product-type' && @.value == 'Credit')]",
    dependencyType: "TENANT",
  },
  {
    name: "Installment Payment Plans",
    route: "installment-payment-plans",
    element: <InstallmentPaymentPlans />,
    dependency: "$..productValues[?(@.fieldCode == 'product-type' && @.value == 'Credit')]",
    dependencyType: "TENANT",
    guard: guard("IPP"),
  },
  {
    name: "Insurance Program Templates",
    route: "insurance-program-templates",
    element: <InsuranceProgramTemplatesPage />,
    dependency: "$..productValues[?(@.fieldCode == 'product-type' && @.value == 'Credit')]",
    dependencyType: "TENANT",
    guard: guard("INS"),
  },
  {
    name: "Additional Services Details",
    route: "additional-services-details",
    element: <AdditionalServicesDetails />,
  },
  {
    name: "Client Identification",
    route: "client-identification",
    element: <ClientIdentification />,
  },
  {
    name: "Card and PIN Maintenance",
    route: "card-and-pin-maintenance",
    element: <GeneralCardRelatedParameters />,
  },
  {
    name: "Payment Schemas Services",
    route: "payment-schemas-services",
    element: <PaymentSchemasServices />,
  },
  {
    name: "Fee Settings",
    route: "fees-tenant-configuration",
    element: <FeesTenantConfiguration />,
  },
  {
    name: "Status Management Codes",
    route: "status-management-codes",
    element: <BlockCodesList />,
  },
  {
    name: "Data Framework",
    route: "data-framework",
    element: <DataFrameworkPage />,
  },
  {
    name: "Specification",
    route: "specification",
    element: <SpecificationPage />,
    dependency: "$..userRoles..roleRestrictions[?(@.restrictionsObjectLevel=='TECHNICAL')]",
    dependencyType: "USER",
  },
  {
    name: "SMS Templates",
    children: [
      {
        name: "General Settings",
        route: "sms-general-settings",
        element: <SmsGeneralSettings />,
      },
      {
        name: "Templates Settings",
        route: "sms-templates",
        element: <SmsTemplates />,
        dependency: "$..tenantValues[?(@.fieldCode =='enable-sms-glob' && @.value == 'true')]",
        dependencyType: "TENANT",
      },
    ],
  },
  {
    name: "Loyalty",
    guard: guard("LTY"),
    children: [
      {
        name: "General Settings",
        route: "loyalty-settings",
        element: <GeneralLoyaltySettings />,
        dependency: "$..loyaltyProgramTemplates[?(@.state=='ACTIVE')]",
        dependencyType: "TENANT",
      },
      {
        name: "Loyalty Program Templates",
        route: "loyalty-program-templates",
        element: <LoyaltyProgramTemplates />,
      },
    ],
  },
  {
    name: "Integrations",
    children: [
      {
        name: "API",
        route: "api",
        element: <APIPage />,
      },
      {
        name: "Interfaces",
        route: "interfaces",
        element: <InterfacesPage />,
      },
      {
        name: "Reports",
        route: "reports",
        element: <ReportsPage />,
      },
    ],
  },
];
