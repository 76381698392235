import { useCallback, useMemo } from "react";
import { Collapse, CollapsePanelProps, notification, Space, Typography, UploadProps } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { AxiosProgressEvent } from "axios";

import { InboxOutlined } from "@ant-design/icons";
import { DevFeatureControllerApi } from "@ni/sdk/apis";

import styles from "./styles.module.scss";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RcCustomRequestOptions<T = any> = Parameters<Exclude<UploadProps<T>["customRequest"], undefined>>[0];

interface Props extends Omit<CollapsePanelProps, "header"> {}

const ImportTenant = ({ ...props }: Props) => {
  const devFeaturesApi = useMemo(() => new DevFeatureControllerApi(), []);

  const handleImportTenant = useCallback(
    (options: RcCustomRequestOptions) => {
      const { file, onSuccess, onError, onProgress } = options;

      devFeaturesApi
        .uploadZip(file, {
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            if (onProgress) {
              const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total!) * 100);
              onProgress({ percent: percentCompleted });
            }
          },
        })
        .then(response => {
          if (response) {
            onSuccess?.(response);

            notification.success({
              message: "Success",
              description: "Tenant data has been imported successfully",
            });
          } else {
            onError?.({
              name: "error",
              status: 400,
              message: "An error occurred while importing tenant data. Please make sure data is valid",
            });
          }
        })
        .catch(() => {});
    },
    [devFeaturesApi],
  );

  return (
    <Collapse.Panel {...props} header="Import Tenant">
      <Space direction="vertical" size={16} className="w-p-100">
        <div>
          <Typography.Text strong={true}>Import</Typography.Text>
          <Typography.Paragraph type="secondary" style={{ maxWidth: 880, width: 880 }}>
            This feature is used to import tenant data. Please provide a single .zip file to upload.
          </Typography.Paragraph>
        </div>

        <div style={{ width: 500, maxWidth: 500 }}>
          <Dragger {...props} accept=".zip" multiple={false} customRequest={handleImportTenant} withCredentials={true}>
            <InboxOutlined className={styles["ni-drag-icon"]} />
            <Typography.Paragraph className={styles["ni-drag-text"]}>
              Click or drag file to this area to upload
            </Typography.Paragraph>
          </Dragger>
        </div>
      </Space>
    </Collapse.Panel>
  );
};

export { ImportTenant };
