/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const RoleRestrictionsObjectLevel = {
    TECHNICAL: 'TECHNICAL',
    APPLICATION: 'APPLICATION',
    FI: 'FI',
    DEPLOYMENT: 'DEPLOYMENT',
    TENANT: 'TENANT',
    PRODUCT: 'PRODUCT',
    PCT: 'PCT',
    USER: 'USER',
    USER_ROLE: 'USER_ROLE',
    USER_ROLE_RESTRICTION: 'USER_ROLE_RESTRICTION',
    WORKFLOW: 'WORKFLOW',
    PHASE: 'PHASE',
    PAGE: 'PAGE',
    ELEMENT: 'ELEMENT',
    VALIDATION: 'VALIDATION',
    TEMPLATE: 'TEMPLATE',
    ELEMENT_TEMPLATE: 'ELEMENT_TEMPLATE',
    SMS_TEMPLATE: 'SMS_TEMPLATE',
    DICTIONARY: 'DICTIONARY',
    VARIABLE: 'VARIABLE',
    FEATURE: 'FEATURE'
} as const;

export type RoleRestrictionsObjectLevel = typeof RoleRestrictionsObjectLevel[keyof typeof RoleRestrictionsObjectLevel];



