import { useCallback } from "react";
import { Button, Flex, List, Pagination, Space } from "antd";
import { Link, useParams } from "react-router-dom";

import { useProductStateFormDisabled, useReduxState } from "@ni/common/hooks";

import { FilterControls } from "../FilterControls";

import { ModalState, PctActions, PctModal } from "./components";
import { inputs } from "./constants";
import { useClientPct } from "./hooks";

import styles from "./styles.module.scss";

const PctList = () => {
  const [isLoading] = useReduxState<boolean>("isLoading");
  const [isPrintMode] = useReduxState<boolean>("isPrintMode");
  const [modalState, setModalState] = useReduxState<ModalState>("pctModal", { pct: {}, type: null });

  const { id: tenantId, productId } = useParams<{ id: string; productId: string }>();
  const { paginatedData, dataSource, setFilters, currentPage, setCurrentPage, pageSize, setPageSize } = useClientPct();

  const isDisabled = useProductStateFormDisabled();

  const onCreateNewTable = useCallback(() => {
    setModalState({ pct: {}, type: "create" });
  }, [setModalState]);

  return (
    <Space direction="vertical" className={styles["pct-list"]}>
      <List
        dataSource={paginatedData}
        loading={isLoading}
        header={<FilterControls inputs={inputs} searchName="search" onValuesChange={setFilters} inputWidth={250} />}
        renderItem={item => (
          <List.Item
            key={item.id}
            actions={
              !isDisabled && !isPrintMode
                ? PctActions({ pct: item, pctLength: dataSource.length, setModalState })
                : undefined
            }
          >
            <Link to={`/tenant/${tenantId}/product/${productId}/pct/${item.id}/settings-overview`} className="w-p-100">
              <List.Item.Meta title={item.displayName} description={item.externalCode} />
            </Link>
          </List.Item>
        )}
        footer={
          <Flex justify="space-between" align="center">
            {!isPrintMode && (
              <Button
                type="primary"
                htmlType="button"
                onClick={onCreateNewTable}
                disabled={isDisabled}
                style={{ width: 200, height: 38 }}
              >
                Create New Table
              </Button>
            )}

            <Pagination
              size="small"
              current={currentPage}
              pageSize={pageSize}
              total={dataSource.length}
              onChange={(page, pageSize) => {
                setCurrentPage(page);
                setPageSize(pageSize);
              }}
              showSizeChanger={true}
              style={{ marginLeft: "auto" }}
            />
          </Flex>
        }
      />

      <PctModal pct={modalState?.pct} />
    </Space>
  );
};

export { PctList };
