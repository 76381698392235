import { useMemo } from "react";
import { Descriptions, DescriptionsProps, Divider, Space, Typography } from "antd";
import { Link, useParams } from "react-router-dom";

import { SettingsPageLayout } from "@ni/common/ui";

import { DeploymentDetailsTable, IntegrationsTable } from "./components";
import { useDeploymentDetails } from "./useDeploymentDetails";

import styles from "./styles.module.scss";

const DeploymentDetails = () => {
  const { deploymentId } = useParams();
  const { deployment, retryDeployment } = useDeploymentDetails({
    deploymentId: Number(deploymentId) ?? 0,
  });

  const { stageItems } = useMemo(() => {
    const stageItems: DescriptionsProps["items"] = [
      {
        key: "1",
        label: "Tenant External Code",
        children: deployment.tenantExternalCode,
      },
      {
        key: "2",
        label: "Product External Code",
        children: deployment.productExternalCode,
      },
      {
        key: "3",
        label: "Trace ID",
        children: deployment.traceId && (
          <Typography.Text copyable={true} ellipsis={true}>
            {deployment.traceId}
          </Typography.Text>
        ),
      },
      {
        key: "4",
        label: "Branch Name",
        children: deployment.branchName,
      },
      {
        key: "5",
        label: "Pull Request Link",
        children: deployment.prLink && (
          <Link to={deployment.prLink} target="_blank" rel="noopener noreferrer">
            <Typography.Text copyable={true} ellipsis={true}>
              {deployment.prLink}
            </Typography.Text>
          </Link>
        ),
        span: 2,
      },
    ];

    return { stageItems };
  }, [deployment]);

  return (
    <SettingsPageLayout pageTitle="Deployment Details" size="full" link="/admin/deployments" linkTitle="Deployments">
      <Space direction="vertical" size="large" className="w-p-100">
        <Space direction="vertical" size="large" className="w-p-100">
          <div>
            <Divider orientation="left" orientationMargin={0} style={{ marginTop: 0 }}>
              NetworkOne Stages
            </Divider>

            <Descriptions
              size="middle"
              items={stageItems}
              bordered={true}
              labelStyle={{ fontWeight: 500, color: "black" }}
              className={styles["ni-stage-details"]}
            />
          </div>

          <DeploymentDetailsTable deployment={deployment} retryDeployment={retryDeployment} />
        </Space>

        <IntegrationsTable deployment={deployment} />
      </Space>
    </SettingsPageLayout>
  );
};

export { DeploymentDetails };
