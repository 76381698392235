/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Deployment } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { PageDataFullDeployment } from '../model';
// @ts-ignore
import { SortedFilteredPageRequest } from '../model';
// @ts-ignore
import { UpdateDeploymentResponse } from '../model';
/**
 * DeploymentsApi - axios parameter creator
 * @export
 */
export const DeploymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns page of Dashboard Deployments for given page number, page size and sorting / filtering criteria
         * @summary Get Dashboard Deployments Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardDeployments: async (sortedFilteredPageRequest: SortedFilteredPageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortedFilteredPageRequest' is not null or undefined
            assertParamExists('getDashboardDeployments', 'sortedFilteredPageRequest', sortedFilteredPageRequest)
            const localVarPath = `/api/v1/deployments/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortedFilteredPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Deployment by ID
         * @summary Get Deployment by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeploymentById', 'id', id)
            const localVarPath = `/api/v1/deployments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edits Deployment or adds it if not exists
         * @summary Edits Deployment
         * @param {Deployment} deployment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        put1: async (deployment: Deployment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deployment' is not null or undefined
            assertParamExists('put1', 'deployment', deployment)
            const localVarPath = `/api/v1/deployments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deployment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts new deployment to way4 based on existing deployment with FAILURE state
         * @summary Retry deployment
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryDeployment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retryDeployment', 'id', id)
            const localVarPath = `/api/v1/deployments/{id}/retry`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeploymentsApi - functional programming interface
 * @export
 */
export const DeploymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeploymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns page of Dashboard Deployments for given page number, page size and sorting / filtering criteria
         * @summary Get Dashboard Deployments Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardDeployments(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDataFullDeployment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardDeployments(sortedFilteredPageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Deployment by ID
         * @summary Get Deployment by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeploymentById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Deployment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeploymentById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edits Deployment or adds it if not exists
         * @summary Edits Deployment
         * @param {Deployment} deployment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async put1(deployment: Deployment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDeploymentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.put1(deployment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Starts new deployment to way4 based on existing deployment with FAILURE state
         * @summary Retry deployment
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryDeployment(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDeploymentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryDeployment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeploymentsApi - factory interface
 * @export
 */
export const DeploymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeploymentsApiFp(configuration)
    return {
        /**
         * Returns page of Dashboard Deployments for given page number, page size and sorting / filtering criteria
         * @summary Get Dashboard Deployments Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardDeployments(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: any): AxiosPromise<PageDataFullDeployment> {
            return localVarFp.getDashboardDeployments(sortedFilteredPageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Deployment by ID
         * @summary Get Deployment by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentById(id: number, options?: any): AxiosPromise<Deployment> {
            return localVarFp.getDeploymentById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Edits Deployment or adds it if not exists
         * @summary Edits Deployment
         * @param {Deployment} deployment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        put1(deployment: Deployment, options?: any): AxiosPromise<UpdateDeploymentResponse> {
            return localVarFp.put1(deployment, options).then((request) => request(axios, basePath));
        },
        /**
         * Starts new deployment to way4 based on existing deployment with FAILURE state
         * @summary Retry deployment
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryDeployment(id: number, options?: any): AxiosPromise<UpdateDeploymentResponse> {
            return localVarFp.retryDeployment(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeploymentsApi - interface
 * @export
 * @interface DeploymentsApi
 */
export interface DeploymentsApiInterface {
    /**
     * Returns page of Dashboard Deployments for given page number, page size and sorting / filtering criteria
     * @summary Get Dashboard Deployments Page
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApiInterface
     */
    getDashboardDeployments(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig): AxiosPromise<PageDataFullDeployment>;

    /**
     * Returns Deployment by ID
     * @summary Get Deployment by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApiInterface
     */
    getDeploymentById(id: number, options?: AxiosRequestConfig): AxiosPromise<Deployment>;

    /**
     * Edits Deployment or adds it if not exists
     * @summary Edits Deployment
     * @param {Deployment} deployment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApiInterface
     */
    put1(deployment: Deployment, options?: AxiosRequestConfig): AxiosPromise<UpdateDeploymentResponse>;

    /**
     * Starts new deployment to way4 based on existing deployment with FAILURE state
     * @summary Retry deployment
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApiInterface
     */
    retryDeployment(id: number, options?: AxiosRequestConfig): AxiosPromise<UpdateDeploymentResponse>;

}

/**
 * DeploymentsApi - object-oriented interface
 * @export
 * @class DeploymentsApi
 * @extends {BaseAPI}
 */
export class DeploymentsApi extends BaseAPI implements DeploymentsApiInterface {
    /**
     * Returns page of Dashboard Deployments for given page number, page size and sorting / filtering criteria
     * @summary Get Dashboard Deployments Page
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public getDashboardDeployments(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig) {
        return DeploymentsApiFp(this.configuration).getDashboardDeployments(sortedFilteredPageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Deployment by ID
     * @summary Get Deployment by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public getDeploymentById(id: number, options?: AxiosRequestConfig) {
        return DeploymentsApiFp(this.configuration).getDeploymentById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edits Deployment or adds it if not exists
     * @summary Edits Deployment
     * @param {Deployment} deployment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public put1(deployment: Deployment, options?: AxiosRequestConfig) {
        return DeploymentsApiFp(this.configuration).put1(deployment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Starts new deployment to way4 based on existing deployment with FAILURE state
     * @summary Retry deployment
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public retryDeployment(id: number, options?: AxiosRequestConfig) {
        return DeploymentsApiFp(this.configuration).retryDeployment(id, options).then((request) => request(this.axios, this.basePath));
    }
}
