/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BatchChangeTenantSdmElementsRequest } from '../model';
// @ts-ignore
import { BlockCode } from '../model';
// @ts-ignore
import { ChangeTenantRequest } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { GenericId } from '../model';
// @ts-ignore
import { PageDataFullBaseLoyaltyProgramDashboard } from '../model';
// @ts-ignore
import { PageDataFullDashboardProduct } from '../model';
// @ts-ignore
import { PageDataFullInsuranceProgramTemplateDashboard } from '../model';
// @ts-ignore
import { PageDataFullPlan } from '../model';
// @ts-ignore
import { Region } from '../model';
// @ts-ignore
import { SdmElementDashboardPageData } from '../model';
// @ts-ignore
import { SortedFilteredPageRequest } from '../model';
// @ts-ignore
import { Tenant } from '../model';
// @ts-ignore
import { TenantSdmElement } from '../model';
/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Clears specified code under specified tenant
         * @summary Clear block code by tenant id and code
         * @param {number} tenantId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBlockCode: async (tenantId: number, code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteBlockCode', 'tenantId', tenantId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('deleteBlockCode', 'code', code)
            const localVarPath = `/api/v1/tenants/{tenantId}/block-codes/{code}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes non-boarded tenant by id
         * @summary Delete Tenant by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenantById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTenantById', 'id', id)
            const localVarPath = `/api/v1/tenants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit Tenant by ID
         * @param {ChangeTenantRequest} changeTenantRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTenant: async (changeTenantRequest: ChangeTenantRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeTenantRequest' is not null or undefined
            assertParamExists('editTenant', 'changeTenantRequest', changeTenantRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editTenant', 'id', id)
            const localVarPath = `/api/v1/tenants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeTenantRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Insurance Program Templates belonging specified tenant and matching with specified filters
         * @summary Gets Insurance Program Templates by tenantId and filters
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBaseInsuranceProgramsByTenantId: async (sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortedFilteredPageRequest' is not null or undefined
            assertParamExists('getBaseInsuranceProgramsByTenantId', 'sortedFilteredPageRequest', sortedFilteredPageRequest)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBaseInsuranceProgramsByTenantId', 'tenantId', tenantId)
            const localVarPath = `/api/v1/tenants/{tenantId}/insurance-program-templates/search`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortedFilteredPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Loyalty Program Templates belonging specified tenant and matching with specified filters
         * @summary Gets Loyalty Program Templates by tenantId and filters
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBaseLoyaltyProgramsByTenantId: async (sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortedFilteredPageRequest' is not null or undefined
            assertParamExists('getBaseLoyaltyProgramsByTenantId', 'sortedFilteredPageRequest', sortedFilteredPageRequest)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBaseLoyaltyProgramsByTenantId', 'tenantId', tenantId)
            const localVarPath = `/api/v1/tenants/{tenantId}/loyalty-program-templates/search`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortedFilteredPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns page of Dashboard Tenants for given page number, page size and sorting / filtering criteria
         * @summary Get Dashboard Tenants Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardTenants: async (sortedFilteredPageRequest: SortedFilteredPageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortedFilteredPageRequest' is not null or undefined
            assertParamExists('getDashboardTenants', 'sortedFilteredPageRequest', sortedFilteredPageRequest)
            const localVarPath = `/api/v1/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortedFilteredPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns list of Block Codes
         * @summary Get default Block Codes by code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultBlockCodesByCode: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getDefaultBlockCodesByCode', 'code', code)
            const localVarPath = `/api/v1/tenants/default-block-codes/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns page of Dashboard Products belonging to the specified tenant for given page number, page size and sorting / filtering criteria
         * @summary Get page of Dashboard Products to apply Loyalty Program Template
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsForApply: async (sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortedFilteredPageRequest' is not null or undefined
            assertParamExists('getProductsForApply', 'sortedFilteredPageRequest', sortedFilteredPageRequest)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getProductsForApply', 'tenantId', tenantId)
            const localVarPath = `/api/v1/tenants/{tenantId}/products/search`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortedFilteredPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Regions
         * @summary Get Regions for Tenant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionsForTenant: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tenants/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns SDM data under tenant
         * @summary Gets tenant SDM data
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSdmData: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSdmData', 'id', id)
            const localVarPath = `/api/v1/tenants/{id}/sdm`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns SDM data under tenant that matches with specified filters
         * @summary Gets SDM data under specified tenant
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSdmDataDashboard: async (sortedFilteredPageRequest: SortedFilteredPageRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortedFilteredPageRequest' is not null or undefined
            assertParamExists('getSdmDataDashboard', 'sortedFilteredPageRequest', sortedFilteredPageRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSdmDataDashboard', 'id', id)
            const localVarPath = `/api/v1/tenants/{id}/sdm/dashboard`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortedFilteredPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Tenant by ID
         * @summary Get Tenant by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTenantById', 'id', id)
            const localVarPath = `/api/v1/tenants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns SDM data under tenant that matches with specified filters
         * @summary Gets SDM data under specified tenant
         * @param {BatchChangeTenantSdmElementsRequest} batchChangeTenantSdmElementsRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSdmData: async (batchChangeTenantSdmElementsRequest: BatchChangeTenantSdmElementsRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchChangeTenantSdmElementsRequest' is not null or undefined
            assertParamExists('patchSdmData', 'batchChangeTenantSdmElementsRequest', batchChangeTenantSdmElementsRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchSdmData', 'id', id)
            const localVarPath = `/api/v1/tenants/{id}/sdm`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchChangeTenantSdmElementsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of Plans by tenant id filtered by state and group and non-case-sensitive search by number or by name with pagination
         * @summary Get page of Plans of given Tenant
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchForPlansOfTenant: async (sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortedFilteredPageRequest' is not null or undefined
            assertParamExists('searchForPlansOfTenant', 'sortedFilteredPageRequest', sortedFilteredPageRequest)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('searchForPlansOfTenant', 'tenantId', tenantId)
            const localVarPath = `/api/v1/tenants/{tenantId}/plans/search`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortedFilteredPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantApiAxiosParamCreator(configuration)
    return {
        /**
         * Clears specified code under specified tenant
         * @summary Clear block code by tenant id and code
         * @param {number} tenantId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBlockCode(tenantId: number, code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlockCode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBlockCode(tenantId, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes non-boarded tenant by id
         * @summary Delete Tenant by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTenantById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTenantById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit Tenant by ID
         * @param {ChangeTenantRequest} changeTenantRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editTenant(changeTenantRequest: ChangeTenantRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editTenant(changeTenantRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Insurance Program Templates belonging specified tenant and matching with specified filters
         * @summary Gets Insurance Program Templates by tenantId and filters
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBaseInsuranceProgramsByTenantId(sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDataFullInsuranceProgramTemplateDashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBaseInsuranceProgramsByTenantId(sortedFilteredPageRequest, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Loyalty Program Templates belonging specified tenant and matching with specified filters
         * @summary Gets Loyalty Program Templates by tenantId and filters
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBaseLoyaltyProgramsByTenantId(sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDataFullBaseLoyaltyProgramDashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBaseLoyaltyProgramsByTenantId(sortedFilteredPageRequest, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns page of Dashboard Tenants for given page number, page size and sorting / filtering criteria
         * @summary Get Dashboard Tenants Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardTenants(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDataFullDashboardProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardTenants(sortedFilteredPageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns list of Block Codes
         * @summary Get default Block Codes by code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultBlockCodesByCode(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlockCode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultBlockCodesByCode(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns page of Dashboard Products belonging to the specified tenant for given page number, page size and sorting / filtering criteria
         * @summary Get page of Dashboard Products to apply Loyalty Program Template
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsForApply(sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDataFullDashboardProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsForApply(sortedFilteredPageRequest, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Regions
         * @summary Get Regions for Tenant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegionsForTenant(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Region>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegionsForTenant(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns SDM data under tenant
         * @summary Gets tenant SDM data
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSdmData(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TenantSdmElement>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSdmData(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns SDM data under tenant that matches with specified filters
         * @summary Gets SDM data under specified tenant
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSdmDataDashboard(sortedFilteredPageRequest: SortedFilteredPageRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SdmElementDashboardPageData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSdmDataDashboard(sortedFilteredPageRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Tenant by ID
         * @summary Get Tenant by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns SDM data under tenant that matches with specified filters
         * @summary Gets SDM data under specified tenant
         * @param {BatchChangeTenantSdmElementsRequest} batchChangeTenantSdmElementsRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchSdmData(batchChangeTenantSdmElementsRequest: BatchChangeTenantSdmElementsRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TenantSdmElement>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchSdmData(batchChangeTenantSdmElementsRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of Plans by tenant id filtered by state and group and non-case-sensitive search by number or by name with pagination
         * @summary Get page of Plans of given Tenant
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchForPlansOfTenant(sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDataFullPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchForPlansOfTenant(sortedFilteredPageRequest, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantApiFp(configuration)
    return {
        /**
         * Clears specified code under specified tenant
         * @summary Clear block code by tenant id and code
         * @param {number} tenantId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBlockCode(tenantId: number, code: string, options?: any): AxiosPromise<Array<BlockCode>> {
            return localVarFp.deleteBlockCode(tenantId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes non-boarded tenant by id
         * @summary Delete Tenant by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenantById(id: number, options?: any): AxiosPromise<GenericId> {
            return localVarFp.deleteTenantById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit Tenant by ID
         * @param {ChangeTenantRequest} changeTenantRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTenant(changeTenantRequest: ChangeTenantRequest, id: number, options?: any): AxiosPromise<Tenant> {
            return localVarFp.editTenant(changeTenantRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Insurance Program Templates belonging specified tenant and matching with specified filters
         * @summary Gets Insurance Program Templates by tenantId and filters
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBaseInsuranceProgramsByTenantId(sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options?: any): AxiosPromise<PageDataFullInsuranceProgramTemplateDashboard> {
            return localVarFp.getBaseInsuranceProgramsByTenantId(sortedFilteredPageRequest, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Loyalty Program Templates belonging specified tenant and matching with specified filters
         * @summary Gets Loyalty Program Templates by tenantId and filters
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBaseLoyaltyProgramsByTenantId(sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options?: any): AxiosPromise<PageDataFullBaseLoyaltyProgramDashboard> {
            return localVarFp.getBaseLoyaltyProgramsByTenantId(sortedFilteredPageRequest, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns page of Dashboard Tenants for given page number, page size and sorting / filtering criteria
         * @summary Get Dashboard Tenants Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardTenants(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: any): AxiosPromise<PageDataFullDashboardProduct> {
            return localVarFp.getDashboardTenants(sortedFilteredPageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns list of Block Codes
         * @summary Get default Block Codes by code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultBlockCodesByCode(code: string, options?: any): AxiosPromise<Array<BlockCode>> {
            return localVarFp.getDefaultBlockCodesByCode(code, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns page of Dashboard Products belonging to the specified tenant for given page number, page size and sorting / filtering criteria
         * @summary Get page of Dashboard Products to apply Loyalty Program Template
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsForApply(sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options?: any): AxiosPromise<PageDataFullDashboardProduct> {
            return localVarFp.getProductsForApply(sortedFilteredPageRequest, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Regions
         * @summary Get Regions for Tenant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionsForTenant(options?: any): AxiosPromise<Array<Region>> {
            return localVarFp.getRegionsForTenant(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns SDM data under tenant
         * @summary Gets tenant SDM data
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSdmData(id: number, options?: any): AxiosPromise<Array<TenantSdmElement>> {
            return localVarFp.getSdmData(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns SDM data under tenant that matches with specified filters
         * @summary Gets SDM data under specified tenant
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSdmDataDashboard(sortedFilteredPageRequest: SortedFilteredPageRequest, id: number, options?: any): AxiosPromise<SdmElementDashboardPageData> {
            return localVarFp.getSdmDataDashboard(sortedFilteredPageRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Tenant by ID
         * @summary Get Tenant by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantById(id: number, options?: any): AxiosPromise<Tenant> {
            return localVarFp.getTenantById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns SDM data under tenant that matches with specified filters
         * @summary Gets SDM data under specified tenant
         * @param {BatchChangeTenantSdmElementsRequest} batchChangeTenantSdmElementsRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSdmData(batchChangeTenantSdmElementsRequest: BatchChangeTenantSdmElementsRequest, id: number, options?: any): AxiosPromise<Array<TenantSdmElement>> {
            return localVarFp.patchSdmData(batchChangeTenantSdmElementsRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of Plans by tenant id filtered by state and group and non-case-sensitive search by number or by name with pagination
         * @summary Get page of Plans of given Tenant
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchForPlansOfTenant(sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options?: any): AxiosPromise<PageDataFullPlan> {
            return localVarFp.searchForPlansOfTenant(sortedFilteredPageRequest, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantApi - interface
 * @export
 * @interface TenantApi
 */
export interface TenantApiInterface {
    /**
     * Clears specified code under specified tenant
     * @summary Clear block code by tenant id and code
     * @param {number} tenantId 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    deleteBlockCode(tenantId: number, code: string, options?: AxiosRequestConfig): AxiosPromise<Array<BlockCode>>;

    /**
     * Deletes non-boarded tenant by id
     * @summary Delete Tenant by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    deleteTenantById(id: number, options?: AxiosRequestConfig): AxiosPromise<GenericId>;

    /**
     * 
     * @summary Edit Tenant by ID
     * @param {ChangeTenantRequest} changeTenantRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    editTenant(changeTenantRequest: ChangeTenantRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<Tenant>;

    /**
     * Returns Insurance Program Templates belonging specified tenant and matching with specified filters
     * @summary Gets Insurance Program Templates by tenantId and filters
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {number} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    getBaseInsuranceProgramsByTenantId(sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options?: AxiosRequestConfig): AxiosPromise<PageDataFullInsuranceProgramTemplateDashboard>;

    /**
     * Returns Loyalty Program Templates belonging specified tenant and matching with specified filters
     * @summary Gets Loyalty Program Templates by tenantId and filters
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {number} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    getBaseLoyaltyProgramsByTenantId(sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options?: AxiosRequestConfig): AxiosPromise<PageDataFullBaseLoyaltyProgramDashboard>;

    /**
     * Returns page of Dashboard Tenants for given page number, page size and sorting / filtering criteria
     * @summary Get Dashboard Tenants Page
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    getDashboardTenants(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig): AxiosPromise<PageDataFullDashboardProduct>;

    /**
     * Returns list of Block Codes
     * @summary Get default Block Codes by code
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    getDefaultBlockCodesByCode(code: string, options?: AxiosRequestConfig): AxiosPromise<Array<BlockCode>>;

    /**
     * Returns page of Dashboard Products belonging to the specified tenant for given page number, page size and sorting / filtering criteria
     * @summary Get page of Dashboard Products to apply Loyalty Program Template
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {number} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    getProductsForApply(sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options?: AxiosRequestConfig): AxiosPromise<PageDataFullDashboardProduct>;

    /**
     * Get Regions
     * @summary Get Regions for Tenant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    getRegionsForTenant(options?: AxiosRequestConfig): AxiosPromise<Array<Region>>;

    /**
     * Returns SDM data under tenant
     * @summary Gets tenant SDM data
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    getSdmData(id: number, options?: AxiosRequestConfig): AxiosPromise<Array<TenantSdmElement>>;

    /**
     * Returns SDM data under tenant that matches with specified filters
     * @summary Gets SDM data under specified tenant
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    getSdmDataDashboard(sortedFilteredPageRequest: SortedFilteredPageRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<SdmElementDashboardPageData>;

    /**
     * Returns Tenant by ID
     * @summary Get Tenant by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    getTenantById(id: number, options?: AxiosRequestConfig): AxiosPromise<Tenant>;

    /**
     * Returns SDM data under tenant that matches with specified filters
     * @summary Gets SDM data under specified tenant
     * @param {BatchChangeTenantSdmElementsRequest} batchChangeTenantSdmElementsRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    patchSdmData(batchChangeTenantSdmElementsRequest: BatchChangeTenantSdmElementsRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<Array<TenantSdmElement>>;

    /**
     * Get list of Plans by tenant id filtered by state and group and non-case-sensitive search by number or by name with pagination
     * @summary Get page of Plans of given Tenant
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {number} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    searchForPlansOfTenant(sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options?: AxiosRequestConfig): AxiosPromise<PageDataFullPlan>;

}

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI implements TenantApiInterface {
    /**
     * Clears specified code under specified tenant
     * @summary Clear block code by tenant id and code
     * @param {number} tenantId 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public deleteBlockCode(tenantId: number, code: string, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).deleteBlockCode(tenantId, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes non-boarded tenant by id
     * @summary Delete Tenant by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public deleteTenantById(id: number, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).deleteTenantById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit Tenant by ID
     * @param {ChangeTenantRequest} changeTenantRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public editTenant(changeTenantRequest: ChangeTenantRequest, id: number, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).editTenant(changeTenantRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Insurance Program Templates belonging specified tenant and matching with specified filters
     * @summary Gets Insurance Program Templates by tenantId and filters
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {number} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getBaseInsuranceProgramsByTenantId(sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getBaseInsuranceProgramsByTenantId(sortedFilteredPageRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Loyalty Program Templates belonging specified tenant and matching with specified filters
     * @summary Gets Loyalty Program Templates by tenantId and filters
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {number} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getBaseLoyaltyProgramsByTenantId(sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getBaseLoyaltyProgramsByTenantId(sortedFilteredPageRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns page of Dashboard Tenants for given page number, page size and sorting / filtering criteria
     * @summary Get Dashboard Tenants Page
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getDashboardTenants(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getDashboardTenants(sortedFilteredPageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns list of Block Codes
     * @summary Get default Block Codes by code
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getDefaultBlockCodesByCode(code: string, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getDefaultBlockCodesByCode(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns page of Dashboard Products belonging to the specified tenant for given page number, page size and sorting / filtering criteria
     * @summary Get page of Dashboard Products to apply Loyalty Program Template
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {number} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getProductsForApply(sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getProductsForApply(sortedFilteredPageRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Regions
     * @summary Get Regions for Tenant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getRegionsForTenant(options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getRegionsForTenant(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns SDM data under tenant
     * @summary Gets tenant SDM data
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getSdmData(id: number, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getSdmData(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns SDM data under tenant that matches with specified filters
     * @summary Gets SDM data under specified tenant
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getSdmDataDashboard(sortedFilteredPageRequest: SortedFilteredPageRequest, id: number, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getSdmDataDashboard(sortedFilteredPageRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Tenant by ID
     * @summary Get Tenant by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getTenantById(id: number, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getTenantById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns SDM data under tenant that matches with specified filters
     * @summary Gets SDM data under specified tenant
     * @param {BatchChangeTenantSdmElementsRequest} batchChangeTenantSdmElementsRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public patchSdmData(batchChangeTenantSdmElementsRequest: BatchChangeTenantSdmElementsRequest, id: number, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).patchSdmData(batchChangeTenantSdmElementsRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of Plans by tenant id filtered by state and group and non-case-sensitive search by number or by name with pagination
     * @summary Get page of Plans of given Tenant
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {number} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public searchForPlansOfTenant(sortedFilteredPageRequest: SortedFilteredPageRequest, tenantId: number, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).searchForPlansOfTenant(sortedFilteredPageRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
