import React, { FC, useMemo } from "react";
import cn from "classnames";

import {
  amex,
  defaultAmex,
  defaultMc,
  defaultProductLogo,
  defaultScheme,
  defaultUpi,
  defaultVisa,
  miniMc,
  miniVisa,
  upi,
} from "@ni/common/assets";

import styles from "./styles.module.scss";

interface CardViewProps {
  cardImage: string | undefined;
  prodIps: string | undefined;
  cardSize?: "smallest" | "small" | "medium" | "large";
  className?: string;
}

export const CardView: FC<CardViewProps> = ({ prodIps, cardSize = "large", cardImage, className = "" }) => {
  const getProductMiniIps = useMemo((): string => {
    if (prodIps?.startsWith("Visa")) {
      return miniVisa;
    }
    if (prodIps === "MC") {
      return miniMc;
    }
    if (prodIps === "Union Pay") {
      return upi;
    }
    if (prodIps === "American Express") {
      return amex;
    }
    return defaultScheme;
  }, [prodIps]);

  const getProductDefaultCard = useMemo((): string => {
    if (prodIps?.startsWith("Visa")) {
      return defaultVisa;
    }
    if (prodIps === "MC") {
      return defaultMc;
    }
    if (prodIps === "Union Pay") {
      return defaultUpi;
    }
    if (prodIps === "American Express") {
      return defaultAmex;
    }
    return defaultProductLogo;
  }, [prodIps]);

  return (
    <div className={cn(className, styles[`card-view-${cardSize}`])}>
      {cardImage && (
        <img
          src={cardImage === "default" ? getProductDefaultCard : `${process.env["PUBLIC_URL"]}/${cardImage}.svg`}
          alt="product-logo"
          className={styles["card-image"]}
        />
      )}
      <img id="mini-ips-id" alt="mini-ips" className={styles["mini-ips"]} src={getProductMiniIps} />
    </div>
  );
};
