import { FC, useCallback, useState } from "react";
import { Button, DatePicker, DatePickerProps, Form, FormProps, Input, InputProps, Select, SelectProps } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import cn from "classnames";

import { SearchOutlined } from "@ant-design/icons";
import { Option } from "@ni/common/types";

import { DictionarySelect, DictionarySelectProps } from "../DictionarySelect";
import { FormItemLabel } from "../Labels";

import styles from "./styles.module.scss";

export type FilterInputProps =
  | { type: "select"; name: string; props: SelectProps }
  | { type: "dictionary"; name: string; props: DictionarySelectProps }
  | { type: "input"; name: string; props: InputProps }
  | { type: "date"; name: string; props: DatePickerProps }
  | { type: "dateRange"; name: string; props: RangePickerProps };

interface FilterControlsProps extends FormProps {
  tabOptions?: Option[];
  initialTab?: string;
  inputs: FilterInputProps[];
  inputWidth?: number;
  fullWidth?: boolean;
  searchName?: string;
  onTabClick?: (value: string) => void;
}

export const FilterControls: FC<FilterControlsProps> = ({
  tabOptions,
  initialTab,
  inputs,
  inputWidth = 200,
  fullWidth = false,
  searchName,
  onTabClick,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState<string>(initialTab ?? "");

  const setActiveTabHandler = (value: string) => {
    if (onTabClick) {
      setActiveTab(value);
      onTabClick(value);
    }
  };

  const renderInput = useCallback(({ obj, className }: { obj: FilterInputProps; className: string }) => {
    switch (obj.type) {
      case "select": {
        const { options, ...restProps } = obj.props;

        return (
          <Select {...restProps} className={cn(className, obj.props.className)}>
            {options?.map(option => (
              <Select.Option key={`fc-option-${option.value}`} value={option.value}>
                <FormItemLabel label={option.label} code={option.value as string} />
              </Select.Option>
            ))}
          </Select>
        );
      }

      case "input": {
        const { ...restProps } = obj.props;
        return <Input {...restProps} className={cn(className, obj.props.className)} />;
      }

      case "dictionary": {
        const { code, options, ...restProps } = obj.props;
        return (
          <DictionarySelect
            {...restProps}
            code={code}
            options={options}
            className={cn(className, obj.props.className)}
          />
        );
      }

      case "date": {
        const { ...restProps } = obj.props;
        return <DatePicker {...restProps} className={cn(className, obj.props.className)} />;
      }

      case "dateRange": {
        const { ...restProps } = obj.props;
        return <DatePicker.RangePicker {...restProps} className={cn(className, obj.props.className)} />;
      }

      default:
        return null;
    }
  }, []);

  return (
    <Form {...props} className={cn(styles["fc-header-filters"], props?.className)}>
      <div className={cn(styles["fc-filter-section"], fullWidth && "w-p-100")}>
        {tabOptions && (
          <Button.Group className={styles["fc-filter-tab"]}>
            {tabOptions.map(option => (
              <Button
                key={option.value}
                type="default"
                className={`
                  ${styles["fc-filter-button"]}
                  ${activeTab === option.value && styles["fc-highlighted"]}
                `}
                onClick={() => setActiveTabHandler(option.value)}
              >
                {option.displayValue}
              </Button>
            ))}
          </Button.Group>
        )}

        {inputs.map(el => (
          <Form.Item
            key={`fc-form-${el.name}`}
            name={el.name}
            style={{ minWidth: `${inputWidth}px`, width: `${inputWidth}px`, flex: `1 1 ${inputWidth}px` }}
            className="w-p-100"
          >
            {renderInput({ obj: el, className: styles["fc-filter-select"] })}
          </Form.Item>
        ))}
      </div>

      {searchName && (
        <Form.Item name={searchName}>
          <Input placeholder="Search" prefix={<SearchOutlined />} className={styles["fc-filter-search"]} />
        </Form.Item>
      )}
    </Form>
  );
};
