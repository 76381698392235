import { ReactNode } from "react";
import { InputNumberProps, Space } from "antd";

import {
  NIC_DLQ_DATE_DAYS,
  NIC_DUE_DATE_DAYS,
  NIC_FP_DATE_BASE,
  NIC_SMS_DUE_DAYS,
  NIC_SMS_DUE_FLAG,
} from "@ni/common/constants";
import { FormField, SelectOption } from "@ni/common/types";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}
interface RadioOptions extends Options {
  options: SelectOption[];
}
interface NumericOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
}

export const paymentDueDateOptions: NumericOptions = {
  code: NIC_DUE_DATE_DAYS,
  name: "Payment due offset (days)",
  default: 20,
  decimalsNumber: 0,
  min: 0,
  max: 30,
  tooltip:
    "This sets the number of days after the billing cycle date to determine the payment due date, which is included in the customer’s statement",
};

export const balanceComponentsOptions: RadioOptions = {
  code: NIC_FP_DATE_BASE,
  name: "Full payment date base",
  default: "PDD",
  tooltip: "This sets the key event used to determine the full payment date to avoid interest charges",
  options: [
    {
      label: "Payment due date",
      value: "PDD",
    },
    {
      label: "Statement date",
      value: "STMT",
    },
  ],
};

export const fullPaymentDateOptions: FormField<InputNumberProps> = {
  code: "nic-fp-date-days",
  label: "Full payment date offset (days)",
  tooltip:
    "This sets the number of days added to the base date to calculate the full payment date. For example if set to 2, the full payment date is 2 days after the base date",
  props: {
    min: 0,
    precision: 0,
    controls: false,
  },
};

export const smsDueReminderFlag: Options = {
  code: NIC_SMS_DUE_FLAG,
  name: "Enable SMS due reminders",
  tooltip:
    "Enables the functionality to send SMS reminders for due payments on specified number of days after billing date",
  default: false,
};

export const smsDueReminderOptions: NumericOptions = {
  code: NIC_SMS_DUE_DAYS,
  name: "SMS due reminder days",
  tooltip: (
    <Space direction="vertical">
      <div>
        Sets the number of days after the billing date to send an SMS reminder for due payment. For example if set to 5,
        rhe reminder will be sent 5 days after the statement date
      </div>
      <div>Kindly visit SMS Templates to modify related SMS template</div>
    </Space>
  ),
  decimalsNumber: 0,
  min: 0,
};

export const latePaymentTriggerOptions: RadioOptions = {
  code: "nic-lp-date-base",
  name: "Late Payment trigger date",
  default: "PDD",
  tooltip:
    "This date is used to determine when late payment fees will be charged if a qualified payment is not posted by this time, you can specify corresponding fee amount at fees set up",
  options: [
    {
      label: "Payment due date",
      value: "PDD",
    },
    {
      label: "Statement date",
      value: "STMT",
    },
  ],
};

export const latePaymentDateOptions: NumericOptions = {
  code: "nic-lp-date-days",
  name: "Late payment offset days",
  decimalsNumber: 0,
  default: 2,
  tooltip:
    "This specifies the number of extra days added to the late payment triggering event to compute the late payment fee date.",
  min: 0,
  max: 30,
};

export const delinquencyAgingTriggerOptions: RadioOptions = {
  code: "nic-dlq-date-base",
  name: "Delinquency aging trigger",
  default: "PDD",
  tooltip: "This sets the key event to calculate when the account’s delinquency level advances",
  options: [
    {
      label: "Payment due date",
      value: "PDD",
    },
    {
      label: "Statement date",
      value: "STMT",
    },
  ],
};

export const delinquencyAgingDayOptions: NumericOptions = {
  code: NIC_DLQ_DATE_DAYS,
  name: "Delinquency aging offset (days)",
  decimalsNumber: 0,
  min: 0,
  max: 30,
  tooltip:
    "This sets the number of days added to the delinquency trigger date to calculate the delinquency aging date. For example, if set to 2 and the trigger is the payment due date, the aging date will be 2 days after the payment due date",
  default: 2,
};
