/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApplyInsuranceProgramTemplateFromProductRequest } from '../model';
// @ts-ignore
import { ApplyLoyaltyProgramTemplateFromProductRequest } from '../model';
// @ts-ignore
import { ChangeProductRequest } from '../model';
// @ts-ignore
import { CopyProductRequest } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { GenericId } from '../model';
// @ts-ignore
import { GetProduct } from '../model';
// @ts-ignore
import { InsuranceProgram } from '../model';
// @ts-ignore
import { LoyaltyProgram } from '../model';
// @ts-ignore
import { PageDataFullDashboardProduct } from '../model';
// @ts-ignore
import { Product } from '../model';
// @ts-ignore
import { SortedFilteredPageRequest } from '../model';
/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Apply selected Insurance Program Templates to product
         * @summary Apply selected Insurance Program Templates to product
         * @param {ApplyInsuranceProgramTemplateFromProductRequest} applyInsuranceProgramTemplateFromProductRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyInsuranceProgramTemplates: async (applyInsuranceProgramTemplateFromProductRequest: ApplyInsuranceProgramTemplateFromProductRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyInsuranceProgramTemplateFromProductRequest' is not null or undefined
            assertParamExists('applyInsuranceProgramTemplates', 'applyInsuranceProgramTemplateFromProductRequest', applyInsuranceProgramTemplateFromProductRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('applyInsuranceProgramTemplates', 'id', id)
            const localVarPath = `/api/v1/products/{id}/insurance-programs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyInsuranceProgramTemplateFromProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Apply selected Loyalty Program Templates to product
         * @summary Apply selected Loyalty Program Templates to product
         * @param {ApplyLoyaltyProgramTemplateFromProductRequest} applyLoyaltyProgramTemplateFromProductRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyLoyaltyProgramTemplates: async (applyLoyaltyProgramTemplateFromProductRequest: ApplyLoyaltyProgramTemplateFromProductRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyLoyaltyProgramTemplateFromProductRequest' is not null or undefined
            assertParamExists('applyLoyaltyProgramTemplates', 'applyLoyaltyProgramTemplateFromProductRequest', applyLoyaltyProgramTemplateFromProductRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('applyLoyaltyProgramTemplates', 'id', id)
            const localVarPath = `/api/v1/products/{id}/loyalty-programs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyLoyaltyProgramTemplateFromProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends Product to UAT
         * @summary Confirm Product to UAT
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUat: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('confirmUat', 'id', id)
            const localVarPath = `/api/v1/products/{id}/confirm-uat`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Copies Product by ID with new DisplayName and returns created one
         * @summary Copy product by OriginalId
         * @param {CopyProductRequest} copyProductRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyProduct: async (copyProductRequest: CopyProductRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copyProductRequest' is not null or undefined
            assertParamExists('copyProduct', 'copyProductRequest', copyProductRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('copyProduct', 'id', id)
            const localVarPath = `/api/v1/products/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes non-boarded product by id
         * @summary Delete Product by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProductById', 'id', id)
            const localVarPath = `/api/v1/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates Product and returns result of operation
         * @summary Edit Product by ID
         * @param {ChangeProductRequest} changeProductRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProduct: async (changeProductRequest: ChangeProductRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeProductRequest' is not null or undefined
            assertParamExists('editProduct', 'changeProductRequest', changeProductRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editProduct', 'id', id)
            const localVarPath = `/api/v1/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns page of Dashboard Products for given page number, page size and sorting / filtering criteria
         * @summary Get Dashboard Products Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardProducts: async (sortedFilteredPageRequest: SortedFilteredPageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortedFilteredPageRequest' is not null or undefined
            assertParamExists('getDashboardProducts', 'sortedFilteredPageRequest', sortedFilteredPageRequest)
            const localVarPath = `/api/v1/products/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortedFilteredPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Product by ID
         * @summary Get Product by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProductById', 'id', id)
            const localVarPath = `/api/v1/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Dry runs confirmation of UAT Product
         * @summary Dry run product to UAT
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uatDryRun: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uatDryRun', 'id', id)
            const localVarPath = `/api/v1/products/{id}/uat-dry-run`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * Apply selected Insurance Program Templates to product
         * @summary Apply selected Insurance Program Templates to product
         * @param {ApplyInsuranceProgramTemplateFromProductRequest} applyInsuranceProgramTemplateFromProductRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyInsuranceProgramTemplates(applyInsuranceProgramTemplateFromProductRequest: ApplyInsuranceProgramTemplateFromProductRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InsuranceProgram>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyInsuranceProgramTemplates(applyInsuranceProgramTemplateFromProductRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Apply selected Loyalty Program Templates to product
         * @summary Apply selected Loyalty Program Templates to product
         * @param {ApplyLoyaltyProgramTemplateFromProductRequest} applyLoyaltyProgramTemplateFromProductRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyLoyaltyProgramTemplates(applyLoyaltyProgramTemplateFromProductRequest: ApplyLoyaltyProgramTemplateFromProductRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LoyaltyProgram>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyLoyaltyProgramTemplates(applyLoyaltyProgramTemplateFromProductRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sends Product to UAT
         * @summary Confirm Product to UAT
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmUat(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmUat(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Copies Product by ID with new DisplayName and returns created one
         * @summary Copy product by OriginalId
         * @param {CopyProductRequest} copyProductRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyProduct(copyProductRequest: CopyProductRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyProduct(copyProductRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes non-boarded product by id
         * @summary Delete Product by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates Product and returns result of operation
         * @summary Edit Product by ID
         * @param {ChangeProductRequest} changeProductRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editProduct(changeProductRequest: ChangeProductRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editProduct(changeProductRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns page of Dashboard Products for given page number, page size and sorting / filtering criteria
         * @summary Get Dashboard Products Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardProducts(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDataFullDashboardProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardProducts(sortedFilteredPageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Product by ID
         * @summary Get Product by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Dry runs confirmation of UAT Product
         * @summary Dry run product to UAT
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uatDryRun(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uatDryRun(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * Apply selected Insurance Program Templates to product
         * @summary Apply selected Insurance Program Templates to product
         * @param {ApplyInsuranceProgramTemplateFromProductRequest} applyInsuranceProgramTemplateFromProductRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyInsuranceProgramTemplates(applyInsuranceProgramTemplateFromProductRequest: ApplyInsuranceProgramTemplateFromProductRequest, id: number, options?: any): AxiosPromise<Array<InsuranceProgram>> {
            return localVarFp.applyInsuranceProgramTemplates(applyInsuranceProgramTemplateFromProductRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Apply selected Loyalty Program Templates to product
         * @summary Apply selected Loyalty Program Templates to product
         * @param {ApplyLoyaltyProgramTemplateFromProductRequest} applyLoyaltyProgramTemplateFromProductRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyLoyaltyProgramTemplates(applyLoyaltyProgramTemplateFromProductRequest: ApplyLoyaltyProgramTemplateFromProductRequest, id: number, options?: any): AxiosPromise<Array<LoyaltyProgram>> {
            return localVarFp.applyLoyaltyProgramTemplates(applyLoyaltyProgramTemplateFromProductRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends Product to UAT
         * @summary Confirm Product to UAT
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUat(id: number, options?: any): AxiosPromise<Product> {
            return localVarFp.confirmUat(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Copies Product by ID with new DisplayName and returns created one
         * @summary Copy product by OriginalId
         * @param {CopyProductRequest} copyProductRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyProduct(copyProductRequest: CopyProductRequest, id: number, options?: any): AxiosPromise<Product> {
            return localVarFp.copyProduct(copyProductRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes non-boarded product by id
         * @summary Delete Product by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductById(id: number, options?: any): AxiosPromise<GenericId> {
            return localVarFp.deleteProductById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates Product and returns result of operation
         * @summary Edit Product by ID
         * @param {ChangeProductRequest} changeProductRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProduct(changeProductRequest: ChangeProductRequest, id: number, options?: any): AxiosPromise<Product> {
            return localVarFp.editProduct(changeProductRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns page of Dashboard Products for given page number, page size and sorting / filtering criteria
         * @summary Get Dashboard Products Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardProducts(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: any): AxiosPromise<PageDataFullDashboardProduct> {
            return localVarFp.getDashboardProducts(sortedFilteredPageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Product by ID
         * @summary Get Product by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById(id: number, options?: any): AxiosPromise<GetProduct> {
            return localVarFp.getProductById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Dry runs confirmation of UAT Product
         * @summary Dry run product to UAT
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uatDryRun(id: number, options?: any): AxiosPromise<Product> {
            return localVarFp.uatDryRun(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - interface
 * @export
 * @interface ProductApi
 */
export interface ProductApiInterface {
    /**
     * Apply selected Insurance Program Templates to product
     * @summary Apply selected Insurance Program Templates to product
     * @param {ApplyInsuranceProgramTemplateFromProductRequest} applyInsuranceProgramTemplateFromProductRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApiInterface
     */
    applyInsuranceProgramTemplates(applyInsuranceProgramTemplateFromProductRequest: ApplyInsuranceProgramTemplateFromProductRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<Array<InsuranceProgram>>;

    /**
     * Apply selected Loyalty Program Templates to product
     * @summary Apply selected Loyalty Program Templates to product
     * @param {ApplyLoyaltyProgramTemplateFromProductRequest} applyLoyaltyProgramTemplateFromProductRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApiInterface
     */
    applyLoyaltyProgramTemplates(applyLoyaltyProgramTemplateFromProductRequest: ApplyLoyaltyProgramTemplateFromProductRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<Array<LoyaltyProgram>>;

    /**
     * Sends Product to UAT
     * @summary Confirm Product to UAT
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApiInterface
     */
    confirmUat(id: number, options?: AxiosRequestConfig): AxiosPromise<Product>;

    /**
     * Copies Product by ID with new DisplayName and returns created one
     * @summary Copy product by OriginalId
     * @param {CopyProductRequest} copyProductRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApiInterface
     */
    copyProduct(copyProductRequest: CopyProductRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<Product>;

    /**
     * Deletes non-boarded product by id
     * @summary Delete Product by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApiInterface
     */
    deleteProductById(id: number, options?: AxiosRequestConfig): AxiosPromise<GenericId>;

    /**
     * Updates Product and returns result of operation
     * @summary Edit Product by ID
     * @param {ChangeProductRequest} changeProductRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApiInterface
     */
    editProduct(changeProductRequest: ChangeProductRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<Product>;

    /**
     * Returns page of Dashboard Products for given page number, page size and sorting / filtering criteria
     * @summary Get Dashboard Products Page
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApiInterface
     */
    getDashboardProducts(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig): AxiosPromise<PageDataFullDashboardProduct>;

    /**
     * Returns Product by ID
     * @summary Get Product by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApiInterface
     */
    getProductById(id: number, options?: AxiosRequestConfig): AxiosPromise<GetProduct>;

    /**
     * Dry runs confirmation of UAT Product
     * @summary Dry run product to UAT
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApiInterface
     */
    uatDryRun(id: number, options?: AxiosRequestConfig): AxiosPromise<Product>;

}

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI implements ProductApiInterface {
    /**
     * Apply selected Insurance Program Templates to product
     * @summary Apply selected Insurance Program Templates to product
     * @param {ApplyInsuranceProgramTemplateFromProductRequest} applyInsuranceProgramTemplateFromProductRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public applyInsuranceProgramTemplates(applyInsuranceProgramTemplateFromProductRequest: ApplyInsuranceProgramTemplateFromProductRequest, id: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).applyInsuranceProgramTemplates(applyInsuranceProgramTemplateFromProductRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Apply selected Loyalty Program Templates to product
     * @summary Apply selected Loyalty Program Templates to product
     * @param {ApplyLoyaltyProgramTemplateFromProductRequest} applyLoyaltyProgramTemplateFromProductRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public applyLoyaltyProgramTemplates(applyLoyaltyProgramTemplateFromProductRequest: ApplyLoyaltyProgramTemplateFromProductRequest, id: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).applyLoyaltyProgramTemplates(applyLoyaltyProgramTemplateFromProductRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends Product to UAT
     * @summary Confirm Product to UAT
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public confirmUat(id: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).confirmUat(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Copies Product by ID with new DisplayName and returns created one
     * @summary Copy product by OriginalId
     * @param {CopyProductRequest} copyProductRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public copyProduct(copyProductRequest: CopyProductRequest, id: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).copyProduct(copyProductRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes non-boarded product by id
     * @summary Delete Product by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public deleteProductById(id: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).deleteProductById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates Product and returns result of operation
     * @summary Edit Product by ID
     * @param {ChangeProductRequest} changeProductRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public editProduct(changeProductRequest: ChangeProductRequest, id: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).editProduct(changeProductRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns page of Dashboard Products for given page number, page size and sorting / filtering criteria
     * @summary Get Dashboard Products Page
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getDashboardProducts(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getDashboardProducts(sortedFilteredPageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Product by ID
     * @summary Get Product by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductById(id: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProductById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Dry runs confirmation of UAT Product
     * @summary Dry run product to UAT
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public uatDryRun(id: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).uatDryRun(id, options).then((request) => request(this.axios, this.basePath));
    }
}
