import { useCallback, useEffect, useState } from "react";
import { notification, TablePaginationConfig } from "antd";

import { SERVICE_UNAVAILABLE } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { getErrorInstance, replaceObjectById } from "@ni/common/utils";
import { DeploymentsApi } from "@ni/sdk/apis";
import { Deployment, DeploymentsState, SortedFilteredPageRequest } from "@ni/sdk/models";

const deploymetsApi = new DeploymentsApi();
const pollInterval = 30000;

const useDeployments = () => {
  const [deploymentList, seDeploymentList] = useReduxState<Deployment[]>("deploymentsList", []);
  const [, setIsLoading] = useReduxState("isLoading", false);

  const [deploymentsFilters, setDeploymentsFilters] = useState<SortedFilteredPageRequest>({});

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: 10,
    current: 1,
    total: 0,
    showSizeChanger: true,
  });

  const fetchDepeloyments = useCallback(
    (filters: SortedFilteredPageRequest, currentPage: number, size: number, isLoading?: boolean) => {
      if (isLoading) setIsLoading(true);

      deploymetsApi
        .getDashboardDeployments({
          ...filters,
          pageLimits: {
            number: currentPage - 1,
            size,
          },
        })
        .then(response => {
          if (response.data.content && response.data.content?.length > 0) {
            setPagination(pagination => ({ ...pagination, total: response.data.totalElements }));
          }
          seDeploymentList(response.data?.content ?? []);
        })
        .catch(() => {})
        .finally(() => {
          if (isLoading) setIsLoading(false);
        });
    },
    [seDeploymentList, setIsLoading, setPagination],
  );

  const fetchDepeloymentById = (id: number) => {
    setIsLoading(true);
    deploymetsApi
      .getDeploymentById(id)
      .then(response => {
        if (deploymentList.length > 0) {
          const newLS = replaceObjectById(deploymentList, response?.data);
          seDeploymentList(newLS);
        }

        setIsLoading(false);
      })
      .catch(error => {
        const errorInstance = getErrorInstance(error);
        notification.error({
          placement: "topRight",
          duration: 3,
          message: (
            <div>
              {errorInstance?.response.status ?? 400} <br />
              {SERVICE_UNAVAILABLE}
            </div>
          ),
        });
        setIsLoading(false);
      });
  };

  const reTryDeployment = (deploymentId: number) => {
    setIsLoading(true);
    deploymetsApi
      .retryDeployment(deploymentId)
      .then(() => {
        notification.success({
          placement: "topRight",
          duration: 3,
          message: <div>Success!</div>,
        });

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const resetPagination = useCallback(() => setPagination(prev => ({ ...prev, current: 1, total: 0 })), []);

  useEffect(() => {
    fetchDepeloyments(deploymentsFilters, pagination.current ?? 1, pagination.pageSize ?? 10, true);

    const pollDeployments = setInterval(() => {
      if (!deploymentList.length || deploymentList.some(x => x.state === DeploymentsState.IN_PROGRESS)) {
        fetchDepeloyments(deploymentsFilters, pagination.current ?? 1, pagination.pageSize ?? 10, false);
      } else {
        clearInterval(pollDeployments);
      }
    }, pollInterval);

    return () => {
      clearInterval(pollDeployments);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deploymentsFilters, fetchDepeloyments, pagination.current, pagination.pageSize]);

  return {
    deploymentList,
    pagination,
    setPagination,
    setDeploymentsFilters,
    fetchDepeloyments,
    fetchDepeloymentById,
    reTryDeployment,
    resetPagination,
  };
};

export { useDeployments };
