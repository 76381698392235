import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Alert, Badge, Button, Collapse, Flex, Table, TableColumnType, Typography } from "antd";
import { ExpandableConfig } from "antd/lib/table/interface";
import { getStatusColor } from "libs/admin/src/lib/utils";

import { ReloadOutlined } from "@ant-design/icons";
import { EMPTY_DATA_FLAG } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { dateFormat, durationConvertHandler } from "@ni/common/utils";
import { Deployment, DeploymentDetails, DeploymentsState } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface Props {
  deployment: Deployment;
  retryDeployment: (deploymentId: number) => void;
}

const DeploymentDetailsTable = ({ deployment, retryDeployment }: Props) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
  const [activeKey, setActiveKey] = useState<string | string[]>([]);
  const [isLoading] = useReduxState<boolean>("isLoading");

  useLayoutEffect(() => {
    if (deployment.reason) {
      const errorId: React.Key | undefined = deployment.deploymentDetails?.find(
        ({ stageState }) => stageState === DeploymentsState.FAILURE,
      )?.id;

      if (errorId) {
        setExpandedRowKeys([errorId]);
        setActiveKey(["1"]);
      }
    }

    if (deployment.state === DeploymentsState.IN_PROGRESS && deployment.deploymentDetails?.length) {
      setActiveKey(["1"]);
    }

    return () => {
      setExpandedRowKeys([]);
      setActiveKey([]);
    };
  }, [deployment.deploymentDetails, deployment.reason, deployment.state]);

  const { deploymentData, deploymentColumns, shouldRetryDeployment } = useMemo(() => {
    const deploymentData: Deployment = {
      ...deployment,
      deploymentDetails: [...(deployment.deploymentDetails ?? [])]?.sort(
        (a, b) => new Date(a.startTime!).getTime() - new Date(b.startTime!).getTime(),
      ),
    };

    const deploymentColumns: TableColumnType<DeploymentDetails>[] = [
      {
        title: "Stage",
        dataIndex: ["stageName"],
        key: "stageName",
        width: "30%",
        rowScope: "row",
        ellipsis: true,
      },
      {
        title: "Start Time",
        dataIndex: ["startTime"],
        key: "startTime",
        width: "20%",
        ellipsis: true,
        render: (_: string, { startTime }: DeploymentDetails) => (startTime ? dateFormat(startTime) : EMPTY_DATA_FLAG),
      },
      {
        title: "Total Time",
        dataIndex: ["totalTime"],
        key: "totalTime",
        width: "20%",
        ellipsis: true,
        render: (_: string, { totalTime }: DeploymentDetails) =>
          totalTime ? <>{durationConvertHandler(totalTime as string).toFixed(3)} sec</> : EMPTY_DATA_FLAG,
      },
      {
        title: "Status",
        dataIndex: ["stageState"],
        key: "stageState",
        width: "5%",
        rowScope: "row",
        ellipsis: true,
        render: (_: string, { stageState }: DeploymentDetails) => <Badge {...getStatusColor(stageState!)} />,
      },
    ];

    return {
      deploymentData,
      deploymentColumns,
      shouldRetryDeployment: deployment.state === DeploymentsState.FAILURE,
    };
  }, [deployment]);

  const CollapseHeader = () => <Typography.Text strong={true}>Deployment Stages</Typography.Text>;

  const CollapseExtra = () => (
    <>
      {!shouldRetryDeployment && (
        <Flex justify="flex-start" align="center">
          <Badge {...getStatusColor(deploymentData.state!)} />
        </Flex>
      )}

      {shouldRetryDeployment && (
        <Button
          size="small"
          type="link"
          icon={<ReloadOutlined />}
          loading={isLoading}
          onClick={() => retryDeployment(deploymentData.id!)}
          style={{ marginLeft: "auto", padding: 0, fontSize: 13 }}
        >
          Retry Deployment
        </Button>
      )}
    </>
  );

  const FailedStageAlert = () => {
    const onClose = () => setExpandedRowKeys([]);

    return (
      <Alert
        type="error"
        message="An Error Occurred"
        description={deployment.reason}
        onClose={onClose}
        showIcon={true}
        closable={true}
      />
    );
  };

  const onExpand = useCallback((_: boolean, record: DeploymentDetails) => {
    setExpandedRowKeys(prev =>
      prev.includes(record.id as React.Key)
        ? prev.filter(key => key !== record.id)
        : ([...prev, record.id] as React.Key[]),
    );
  }, []);

  const expandable: ExpandableConfig<Deployment> = {
    expandedRowKeys,
    expandIconColumnIndex: -1,
    onExpand,
    expandedRowRender: () => <FailedStageAlert />,
  };

  return (
    <Collapse activeKey={activeKey} onChange={setActiveKey} className={styles["ni-collapse"]}>
      <Collapse.Panel key="1" header={<CollapseHeader />} extra={<CollapseExtra />}>
        <Table<Deployment>
          size="middle"
          rowKey="id"
          columns={deploymentColumns}
          dataSource={deploymentData.deploymentDetails}
          expandable={expandable}
          loading={isLoading}
          pagination={false}
          bordered={true}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export { DeploymentDetailsTable };
