import { useEffect } from "react";
import { Form, notification } from "antd";
import { useParams } from "react-router-dom";

import { SERVICE_UNAVAILABLE } from "@ni/common/constants";
import { useProductSettings, useProductStateFormDisabled } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, DynamicSwitchForm } from "@ni/common/ui";
import { getErrorInstance } from "@ni/common/utils";
import { checkboxFieldsPage9, checkHeader, getLimitsPreparedValues, switchHeader9 } from "@ni/tenant-portal/core";

import { usePct } from "../../../../hooks";
import { prepareFieldsToRender } from "../../../../utils";

import { cashRules, retailRules, totalRules } from "./constants";

export const AccountLimitsDailyPage = () => {
  const { productId, pctId } = useParams<{ productId: string; pctId: string }>();
  const { productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const { pct, getValueFromPct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const [form] = Form.useForm<FormValues>();

  const formDisabled = useProductStateFormDisabled();

  useEffect(() => {
    prepareFieldsToRender(checkboxFieldsPage9, (fieldName: string) => {
      form.setFieldValue(fieldName, getValueFromPct(fieldName));
    });

    Object.keys(checkboxFieldsPage9).forEach(key => {
      checkHeader(key, checkboxFieldsPage9, form);
    });

    checkHeader("acc-daily-all-cash-enable", switchHeader9, form);
    checkHeader("acc-daily-all-retail-enable", switchHeader9, form);
    checkHeader("acc-daily-all-total-enable", switchHeader9, form);
  }, [form, getValueFromPct, pct]);

  const onFinish = async (values: FormValues): Promise<void> => {
    try {
      let actualValues: FormValues = {};
      actualValues = {
        ...actualValues,
        ...getLimitsPreparedValues(values as { [key: string]: string | boolean }, checkboxFieldsPage9),
      };

      await onSavePct(actualValues);
    } catch (error) {
      const errorInstance = getErrorInstance(error);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response?.status ?? 400} <br />
            {SERVICE_UNAVAILABLE}
          </div>
        ),
      });
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Account Limits - Daily"
      pageSubtitle="Account limits govern the spending velocity for the cards related to the account. Both primary and supplementary card transactions contribute towards the account limits."
      submitHandler={onFinish}
      size="md"
      formSize="full"
      level="pct"
      submitLabel="Save"
      gap={24}
    >
      <DynamicSwitchForm
        form={form}
        currency={productCurrency}
        name="acc-daily-all-cash-enable"
        title="Cash"
        tooltip="Turns off all cash limits."
        rules={cashRules}
        disabled={formDisabled}
      />

      <DynamicSwitchForm
        form={form}
        currency={productCurrency}
        name="acc-daily-all-retail-enable"
        title="Retail"
        tooltip="Turns off all retail limits."
        rules={retailRules}
        disabled={formDisabled}
      />

      <DynamicSwitchForm
        form={form}
        currency={productCurrency}
        name="acc-daily-all-total-enable"
        title="Total"
        tooltip="Total limiter that includes all transactions (Cash and Retail). Turn it off, if restrictions are not intended."
        rules={totalRules}
        disabled={formDisabled}
      />
    </CustomFormWrapper>
  );
};
