import { useEffect } from "react";
import { Form, notification } from "antd";
import { useParams } from "react-router-dom";

import { SERVICE_UNAVAILABLE } from "@ni/common/constants";
import { useProductSettings, useProductStateFormDisabled } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, DynamicSwitchForm } from "@ni/common/ui";
import { getErrorInstance } from "@ni/common/utils";
import {
  CardBillingSwitchHeaders,
  checkboxFieldsPage35,
  checkHeader,
  getLimitsPreparedValues,
  switchHeader35,
} from "@ni/tenant-portal/core";

import { usePct } from "../../../../hooks";
import { prepareFieldsToRender } from "../../../../utils";

import { cashRules, retailRules } from "./constants";

export const CardLimitsBillingPage = () => {
  const { productId, pctId } = useParams<{ productId: string; pctId: string }>();
  const { productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const { pct, getValueFromPct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const [form] = Form.useForm<FormValues>();

  const formDisabled = useProductStateFormDisabled();

  useEffect(() => {
    prepareFieldsToRender(checkboxFieldsPage35, (fieldName: string) => {
      form.setFieldValue(fieldName, getValueFromPct(fieldName));
    });

    Object.keys(checkboxFieldsPage35).forEach(key => {
      checkHeader(key, checkboxFieldsPage35, form);
    });

    checkHeader(CardBillingSwitchHeaders.allCashAtmEnable, switchHeader35, form);
    checkHeader(CardBillingSwitchHeaders.allRetailEnable, switchHeader35, form);
  }, [form, getValueFromPct, pct]);

  const onFinish = async (values: FormValues): Promise<void> => {
    try {
      let actualValues = {};
      actualValues = {
        ...actualValues,
        ...getLimitsPreparedValues(values as { [key: string]: string | boolean }, checkboxFieldsPage35),
      };

      await onSavePct(actualValues as unknown as FormValues);
    } catch (error) {
      const errorInstance = getErrorInstance(error);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response?.status ?? 400} <br />
            {SERVICE_UNAVAILABLE}
          </div>
        ),
      });
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Card Limits - Billing Period"
      pageSubtitle="Card daily limits control spending on the card level independently from account limits, which even limit is reached first."
      submitHandler={onFinish}
      size="md"
      formSize="full"
      level="pct"
      submitLabel="Save"
      gap={24}
    >
      <DynamicSwitchForm
        form={form}
        currency={productCurrency}
        name="card-monthly-all-cash-enable"
        title="Cash"
        tooltip="Turns off all cash limits."
        rules={cashRules}
        disabled={formDisabled}
      />

      <DynamicSwitchForm
        form={form}
        currency={productCurrency}
        name="card-monthly-all-retail-enable"
        title="Retail"
        tooltip="Turns off all retail transactions."
        rules={retailRules}
        disabled={formDisabled}
      />
    </CustomFormWrapper>
  );
};
