import { useCallback, useMemo, useState } from "react";
import { Button, Flex, Input, notification, Select, Space, Tooltip, Typography } from "antd";
import { useParams } from "react-router-dom";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { SettingsPageLayout } from "@ni/common/ui";
import { TenantApi } from "@ni/sdk/apis";

interface Payload {
  type: "json" | "string" | "number" | "boolean";
  key: string;
  value: string;
}

const SpecificationPage = () => {
  const [qpPayload, setQpPayload] = useState<Payload[]>([{ type: "json", key: "", value: "" }]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id: tenantId } = useParams<{ id: string }>();

  const tenantApi = useMemo(() => new TenantApi(), []);

  const handleInputChange = useCallback((index: number, field: string, value: string) => {
    setQpPayload(prev => prev.map((item, i) => (i === index ? { ...item, [field]: value } : item)));
  }, []);

  const options = [
    { label: "Json", value: "json" },
    { label: "String", value: "string" },
    { label: "Number", value: "number" },
    { label: "Boolean", value: "boolean" },
  ];

  const parseValue = (type: string, value: string) => {
    switch (type) {
      case "number":
        return Number(value);
      case "boolean":
        return value.toLowerCase() === "true";
      case "json":
        try {
          return JSON.parse(value) as object;
        } catch {
          return value;
        }
      default:
        return value;
    }
  };

  const onRun = () => {
    setIsLoading(true);

    const parsedPayload = qpPayload.reduce(
      (acc, { key, value, type }) => {
        if (key) {
          acc[key] = parseValue(type, value);
        }
        return acc;
      },
      {} as { [key: string]: object },
    );

    tenantApi
      .editTenant({ specs: parsedPayload }, parseInt(tenantId ?? "0", 10))
      .then(() => {
        notification.success({
          message: "Success",
          description: "Operation executed successfully",
        });
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const isDisabled = useMemo(() => !qpPayload.every(x => x.key && x.value), [qpPayload]);

  return (
    <SettingsPageLayout size="full" pageTitle="Specification">
      <Flex vertical={true} gap={8} style={{ width: 700 }}>
        <Flex align="center" gap={8}>
          <Typography.Text strong={true}>Payload</Typography.Text>
          <Tooltip title="Add new object">
            <Button
              size="small"
              type="dashed"
              icon={<PlusOutlined />}
              onClick={() => setQpPayload([...qpPayload, { type: "json", key: "", value: "" }])}
            />
          </Tooltip>
        </Flex>

        {qpPayload.map((item, index) => (
          <Flex key={`object-${index + 1}`} vertical={true} gap={8}>
            <Flex align="center" gap={8}>
              <Space.Compact className="w-p-100">
                <Select onChange={e => handleInputChange(index, "type", e)} value={item.type} options={options} />
                <Input
                  placeholder="Key"
                  onChange={e => handleInputChange(index, "key", e.target.value)}
                  value={item.key}
                />
              </Space.Compact>

              <Input
                placeholder="Value"
                onChange={e => handleInputChange(index, "value", e.target.value)}
                value={item.value}
              />

              <Button
                type="primary"
                shape="circle"
                danger={true}
                icon={<DeleteOutlined />}
                disabled={qpPayload.length === 1}
                tabIndex={-1}
                onClick={() => setQpPayload(qpPayload.filter((_, i) => i !== index))}
              />
            </Flex>
          </Flex>
        ))}

        <Button
          type="primary"
          onClick={onRun}
          loading={isLoading}
          disabled={isDisabled}
          style={{ width: 150, marginTop: 16 }}
        >
          Save
        </Button>
      </Flex>
    </SettingsPageLayout>
  );
};

export { SpecificationPage };
