import { useEffect, useMemo } from "react";
import { Form } from "antd";

import { useDebaunce } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { FilterControls, FilterInputProps, SettingsPageLayout } from "@ni/common/ui";
import { updateFilters } from "@ni/common/utils";

import { DeploymentsTable } from "../../components";

import { inputs } from "./constants";
import { useDeployments } from "./useDeployments";

export const Deployments = () => {
  const [form] = Form.useForm<FormValues>();

  const {
    deploymentList,
    pagination,
    setPagination,
    setDeploymentsFilters,
    fetchDepeloymentById,
    reTryDeployment,
    resetPagination,
  } = useDeployments();

  const searchQuery = Form.useWatch<string>("searchValue", form);
  const debouncedQuery = useDebaunce({ value: searchQuery, delay: 500 });
  const filterKeys = useMemo(
    () => ["deployment.startDate", "deployment.endDate", ...inputs.map((input: FilterInputProps) => input.name)],
    [],
  );

  useEffect(() => {
    if (debouncedQuery !== undefined) {
      setDeploymentsFilters(filters => updateFilters(filterKeys, filters, undefined, debouncedQuery));
      resetPagination();
    }
  }, [debouncedQuery, filterKeys, resetPagination, setDeploymentsFilters, setPagination]);

  const onValuesChange = (changedValues: FormValues) => {
    const filters = changedValues;
    const { searchValue, dateRange } = filters as { searchValue: string; dateRange: Date[] };

    if (typeof searchValue === "string") return;

    const hasDateRange = Array.isArray(dateRange) && dateRange.length > 0;

    if (hasDateRange) {
      filters["deployment.startDate"] = dateRange[0]?.toISOString().replace("Z", "");
      filters["deployment.endDate"] = dateRange[1]?.toISOString().replace("Z", "");
    } else {
      filters["deployment.startDate"] = "";
      filters["deployment.endDate"] = "";
    }

    delete filters["dateRange"];

    setDeploymentsFilters(prevFilters => updateFilters(filterKeys, prevFilters, filters, debouncedQuery));
    resetPagination();
  };

  return (
    <SettingsPageLayout pageTitle="Deployments" size="full">
      <FilterControls
        form={form}
        inputs={inputs}
        searchName="searchValue"
        inputWidth={300}
        onValuesChange={onValuesChange}
      />

      <DeploymentsTable
        deploymentList={deploymentList}
        pagination={pagination}
        setPagination={setPagination}
        fetchDepeloymentById={fetchDepeloymentById}
        reTryDeployment={reTryDeployment}
        setDeploymentsFilters={setDeploymentsFilters}
      />
    </SettingsPageLayout>
  );
};
