import { TablePaginationConfig } from "antd";

import { QpChangePageData, QpDashboardPageData } from "@ni/sdk/models";

export const initialQpData: QpDashboardPageData = {
  content: [],
  hasContent: false,
  hasNext: false,
  hasPrevious: false,
  isEmpty: false,
  isFirst: false,
  isLast: false,
  number: 0,
  numberOfElements: 0,
  size: 0,
  totalElements: 0,
  totalPages: 0,
};

export const initialQpConfirmData: QpChangePageData = {
  content: [],
  hasContent: false,
  hasNext: false,
  hasPrevious: false,
  isEmpty: false,
  isFirst: false,
  isLast: false,
  number: 0,
  numberOfElements: 0,
  size: 0,
  totalElements: 0,
  totalPages: 0,
};

export const initialQpPagination: TablePaginationConfig = {
  pageSize: 100,
  current: 1,
  total: 0,
  showSizeChanger: true,
};
