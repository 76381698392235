import { Button, Dropdown, Flex, MenuProps } from "antd";

import { INTRA_REGIONAL_COUNTRIES, PERSO, PLAN_CURRENCY, PRODUCT_CURRENCY, SCHEME, TENANT_COUNTRY, TENANT_CURRENCY } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { TextIcon } from "@ni/common/icons";
import { Dictionaries } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface DictionairesHeaderProps {
  children: React.ReactNode;
  syncWithWay4: (dictionaryCodes: string[]) => Promise<void>;
  openDrawer: (enumToEdit?: Dictionaries) => void;
}

const dictionaryCodeItems: MenuProps["items"] = [
  {
    label: PERSO,
    key: PERSO,
    icon: <TextIcon />,
  },
  {
    label: SCHEME,
    key: SCHEME,
    icon: <TextIcon />,
  },
  {
    label: PLAN_CURRENCY,
    key: PLAN_CURRENCY,
    icon: <TextIcon />,
  },
  {
    label: PRODUCT_CURRENCY,
    key: PRODUCT_CURRENCY,
    icon: <TextIcon />,
  },
  {
    label: TENANT_CURRENCY,
    key: TENANT_CURRENCY,
    icon: <TextIcon />,
  },
  {
    label: TENANT_COUNTRY,
    key: TENANT_COUNTRY,
    icon: <TextIcon />,
  },
  {
    label: INTRA_REGIONAL_COUNTRIES,
    key: INTRA_REGIONAL_COUNTRIES,
    icon: <TextIcon />,
  },
];

export const DictionairesHeader = ({ children, syncWithWay4, openDrawer }: DictionairesHeaderProps) => {
  const [isLoading] = useReduxState<boolean>("isLoading");

  const handleMenuClick: MenuProps["onClick"] = async e => {
    await syncWithWay4([e.key]);
  };

  const handleSyncClick = async () => {
    const getAllDictionaries = dictionaryCodeItems.map(x => x?.key) as string[];
    await syncWithWay4(getAllDictionaries);
  };

  return (
    <div className={styles["header"]}>
      <Flex gap={24}>
        <Dropdown.Button
          size="middle"
          type="primary"
          disabled={isLoading}
          menu={{ items: dictionaryCodeItems, onClick: handleMenuClick }}
          onClick={handleSyncClick}
          className={styles["sync-button"]}
        >
          Sync with Way4
        </Dropdown.Button>

        <Button
          size="middle"
          type="primary"
          disabled={isLoading}
          onClick={() => openDrawer()}
          className={styles["new-button"]}
        >
          New dictionary
        </Button>
        {children}
      </Flex>
    </div>
  );
};
